<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-[100]">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              :class="isSuperAdmin ? 'sm:w-[50rem]' : 'sm:w-full sm:max-w-lg'"
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6"
            >
              <div class="">
                <div class="mt-3 text-center sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900"
                    >Invite Users</DialogTitle
                  >
                  <div class="mt-2" v-if="init">
                    <div v-for="(user, i) in inviteUsers" :key="i">
                      <div class="flex items-start space-x-3 mt-3">
                        <div
                          class="break-all relative z-10 cursor-pointer text-sm form-select w-96 block text-gray-600 appearance-none bg-transparent"
                        >
                          <input
                            v-model="user.email"
                            type="text"
                            placeholder="example@domain.com"
                            class="block w-full p-5 appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm"
                          />

                          <div v-if="showError && errorValue">
                            <ErrorSpan>
                              <span v-if="errorValue[`invite.${i}.email`]">
                                {{ errorValue[`invite.${i}.email`][0] }}
                              </span>
                            </ErrorSpan>
                          </div>
                        </div>

                        <div
                          class="break-all relative z-10 cursor-pointer text-sm form-select block w-96 text-gray-600 appearance-none bg-transparent"
                        >
                          <ECombobox
                            v-model="user.role_id"
                            :options="filteredRoles"
                            option-name="friendly_name"
                            value-key="id"
                            placeholder="Select Role"
                            :multiple="false"
                            :filterable="false"
                            :clearable="false"
                            @change="handleChangeRole(user)"
                          />

                          <div v-if="showError && errorValue">
                            <ErrorSpan>
                              <span v-if="errorValue[`invite.${i}.role_id`]">
                                {{ errorValue[`invite.${i}.role_id`][0] }}
                              </span>
                            </ErrorSpan>
                          </div>
                        </div>

                        <div
                          v-if="isSuperAdmin"
                          class="break-all relative z-10 cursor-pointer text-sm form-select block w-96 text-gray-600 appearance-none bg-transparent"
                        >
                          <ECombobox
                            v-model="user.dealer_id"
                            :options="companies"
                            option-name="name"
                            value-key="id"
                            placeholder="Select company"
                            :multiple="false"
                            :filterable="false"
                            :clearable="false"
                            @change="handleChangeCompany($event, i, user)"
                          />

                          <div v-if="showError && errorValue">
                            <ErrorSpan>
                              <span v-if="errorValue[`invite.${i}.dealer_id`]">
                                {{ errorValue[`invite.${i}.dealer_id`][0] }}
                              </span>
                            </ErrorSpan>
                          </div>
                        </div>

                        <div
                          v-if="
                            isSuperAdmin ||
                            (user.role_id === dealerManager.id &&
                              !isSpecificManager)
                          "
                          class="break-all relative z-10 cursor-pointer text-sm form-select block w-96 text-gray-600 appearance-none bg-transparent"
                        >
                          <ECombobox
                            v-model="user.specific_dealer_id"
                            :options="user.specific_dealer_list"
                            option-name="name"
                            value-key="id"
                            placeholder="Select store"
                            :multiple="false"
                            :filterable="false"
                            :clearable="false"
                          />

                          <div v-if="showError && errorValue">
                            <ErrorSpan>
                              <span
                                v-if="
                                  errorValue[`invite.${i}.specific_dealer_id`]
                                "
                              >
                                {{
                                  errorValue[
                                    `invite.${i}.specific_dealer_id`
                                  ][0]
                                }}
                              </span>
                            </ErrorSpan>
                          </div>
                        </div>

                        <div class="w-24" v-if="inviteUsers.length > 1">
                          <button
                            @click="handleClickRemove(i)"
                            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 w-auto"
                          >
                            <TrashIcon class="h-5 w-5" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <button
                      type="button"
                      class="mt-5 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 block w-full"
                      :disabled="isLoading"
                      @click="handleClickAdd"
                    >
                      Add Another User
                    </button>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
                  :disabled="isLoading"
                  @click="handleClickCancel"
                  ref="cancelButtonRef"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 mr-3"
                  :disabled="isLoading"
                  @click="handleClickSend"
                >
                  <LoadingSpinner :show="isLoading" />
                  Send
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  ref,
  watch,
  inject,
  onMounted,
} from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { TrashIcon } from "@heroicons/vue/outline";
import ErrorSpan from "../utilities/ErrorSpan.vue";
import LoadingSpinner from "../loaders/LoadingSpinner.vue";
import Bugsnag from "@bugsnag/js";
import ECombobox from "../element-components/ECombobox.vue";
import { useAuthStore } from "@/stores";
import { getRoles } from "@/composable/users/getRoles.js";
import { find as _find } from "lodash-es";
import { getCompanies } from "@/composable/companies/getCompanies";

const myRoles = getRoles();
const isSuperAdmin = myRoles.isSuperAdmin;
const isAccountManager = myRoles.isAccountManager;
const isSpecificManager = myRoles.isSpecificManager;

const { fetchMainCompanies, companies } = getCompanies();

const authUser = useAuthStore();
const currentUser = computed(() => {
  return authUser.$state.user;
});

const axios = inject("axios");

const roles = ref([]);

const specificDealers = ref([]);

const init = ref(false);

const getRolesOption = async () => {
  await axios
    .get("/api/v3/roles")
    .then(({ data }) => {
      roles.value = data;
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
};

const getSpecificDealers = async (index = 0, dealerId = null) => {
  await axios
    .get("/api/v3/specific_dealers", {
      params: {
        dealer_id: dealerId || currentUser.value.dealer_id,
      },
    })
    .then(({ data }) => {
      if (specificDealers.value.length == 0) {
        specificDealers.value = data;
      }
      inviteUsers.value[index].specific_dealer_list = data;
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
};

onMounted(async () => {
  await getRolesOption();
  rolesCondition();

  inviteUsers.value = [
    {
      email: "",
      role_id: salesperson.value.id,
      dealer_id: currentUser.value.dealer_id,
      specific_dealer_id: currentUser.value.specific_dealer_id,
      specific_dealer_list: [],
    },
  ];

  await getSpecificDealers();
  await fetchMainCompanies();
  init.value = true;
});

const props = defineProps({
  open: {
    default: false,
    type: Boolean,
  },
  error: {
    type: Array,
  },
});

const emit = defineEmits(["cancelEvent", "sendEvent"]);

const open = computed(() => props.open);

const errorValue = computed(() => errorData.value || props.error);
const errorData = ref(null);
const showError = ref(false);
const isLoading = ref(false);
const inviteUsers = ref([]);

const superAdmin = computed(() => {
  return findRole("super-administrator");
});

const accountManager = computed(() => {
  return findRole("account-manager");
});

const secretShopper = computed(() => {
  return findRole("secret-shopper");
});

const dealerManager = computed(() => {
  return findRole("specific-dealer-manager");
});

const salesperson = computed(() => {
  return findRole("salesperson");
});

const csm = computed(() => {
  return findRole("csm");
});

const filteredRoles = computed(() => {
  return roles.value.filter((role) => role.value);
});

const rolesCondition = () => {
  superAdmin.value.value = isSuperAdmin.value;
  secretShopper.value.value = isSuperAdmin.value;

  accountManager.value.value = isAccountManager.value || isSuperAdmin.value;

  csm.value.value = false;

  //hide dealer manager for non automotive
  dealerManager.value.value = Boolean(currentUser.value.dealer.is_automotive);

  salesperson.value.value = true;
};
const findRole = (role) => {
  const foundRole = _find(roles.value, {
    name: role,
  });
  return foundRole || {};
};

const handleClickCancel = () => {
  resetInput();
  emit("cancelEvent");
};

const handleClickSend = async () => {
  errorData.value = null;
  isLoading.value = true;

  axios
    .post("/api/v3/invite-users", {
      invite: inviteUsers.value,
    })
    .then(() => {
      emit("sendEvent");
    })
    .catch((error) => {
      if (error.response.status === 422) {
        showError.value = true;
        errorData.value = error.response.data;
      }
      Bugsnag.notify(error);
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const resetInput = () => {
  inviteUsers.value = [
    {
      email: "",
      role_id: salesperson.value.id,
      dealer_id: currentUser.value.dealer_id,
      specific_dealer_id: currentUser.value.specific_dealer_id,
      specific_dealer_list: [],
    },
  ];
  getSpecificDealers();
  errorData.value = null;
};

watch(open, () => {
  if (open.value) {
    resetInput();
  }
});

const handleClickAdd = () => {
  inviteUsers.value.push({
    email: "",
    role_id: salesperson.value.id,
    dealer_id: currentUser.value.dealer_id,
    specific_dealer_id: currentUser.value.specific_dealer_id,
    specific_dealer_list: specificDealers.value,
  });
};

const handleClickRemove = (i) => {
  inviteUsers.value.splice(i, 1);
  errorValue.value = null;
};

const handleChangeRole = (user) => {
  if (
    user &&
    user.role_id === dealerManager.value.id &&
    !isSpecificManager.value
  ) {
    user.specific_dealer_id = null;
  }
};

const handleChangeCompany = (newValue, index, invite) => {
  invite.specific_dealer_id = null;
  invite.specific_dealer_list = [];
  invite.specific_dealer_setting = {
    page: 1,
    per_page: 20,
    total: "",
  };
  getSpecificDealers(index, newValue);
};

watch(
  inviteUsers,
  () => {
    showError.value = false;
  },
  { deep: true }
);
</script>
