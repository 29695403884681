<template>
  <div class="mb-5 pb-5 bg-untitled-gray-100 p-3 rounded-sm">
    <div class="flex items-center">
      <div class="ml-2 w-full">
        <div class="flex justify-start items-center">
          <h3 class="text-untitled-gray-800 font-bold inline">
            Featured Video
          </h3>
          <button
            v-if="isSuperAdmin || isAccountManager"
            @click="openModal"
            type="button"
            class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-untitled-gray-700 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 ml-2"
          >
            <VideoCameraIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            Change Video
          </button>
        </div>

        <div
          class="md:flex items-center w-full mt-5 max-h-full min-h-[250px] bg-black justify-center"
        >
          <div class="w-full" v-if="isFeaturedVideoReady">
            <div
              id="youtube-player"
              class="md:flex items-center w-full mt-5 max-h-full min-h-[250px] bg-black justify-center"
            >
              <iframe
                v-show="isVimeoVideo"
                id="featured-video"
                width="100%"
                height="250"
                :src="
                  !isUploading && featuredVideo?.url
                    ? featuredVideo?.url
                    : defaultVideo
                "
                title="Video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                class="aspect-video"
              ></iframe>
          </div>
          </div>
          <div v-show="!isFeaturedVideoReady">
            <loading-spinner :show="true"></loading-spinner>
          </div>
        </div>
      </div>
    </div>
    <change-video-dialog
      :isOpen="showChangeVideoDialog"
      :handleCloseModal="closeModal"
      @refreshVideo="handleRefreshVideo"
    />
    <NotificationDialog
      v-if="showNotificationDialog"
      @close="showNotificationDialog = false"
      content="Featured video updated successfully"
    />
  </div>
</template>

<script setup>
/* eslint-disable */

import { onMounted, ref, computed, inject } from "vue";
import { VideoCameraIcon } from "@heroicons/vue/solid";
import ChangeVideoDialog from "./featured/ChangeVideoDialog";
import NotificationDialog from "@/components/partials/NotificationDialog.vue";
import { useAuthStore } from "@/stores";
import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import Bugsnag from "@bugsnag/js";

const authUser = useAuthStore();
const axios = inject("axios");

const showChangeVideoDialog = ref(false);
const showNotificationDialog = ref(false);
const featuredVideo = ref(null);
const defaultVideo = ref(null);
const isFeaturedVideoReady = ref(false);
const isUploading = ref(true);
const isVimeoVideo = ref(false);

const openModal = () => {
  showChangeVideoDialog.value = true;
};
const closeModal = () => {
  showChangeVideoDialog.value = false;
};

const handleRefreshVideo = (data) => {
  if(data.uploading){
    return
  }

  showNotificationDialog.value = true;
  getLatestFeaturedVideo()

}
const getLatestFeaturedVideo = () => {
  isFeaturedVideoReady.value = false;

  axios
    .get("/api/get-featured-video")
    .then(({ data }) => {
      isFeaturedVideoReady.value = true;
      featuredVideo.value = data.featured_video;
      isUploading.value = data?.uploading;
      defaultVideo.value = data?.default_video;


      setTimeout(() => {
        isVimeo();
      },2000)
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
};

const isSuperAdmin = computed(() => {
  const user = authUser.user;

  if (!user) return false;

  return user.roles.some((role) => role.name === "super-administrator");
});

const isAccountManager = computed(() => {
  const user = authUser.user;

  if (!user) return false;

  return user.roles.some((role) => role.name === "account-manager");
});

const isVimeo = () => {
  const isVimeo = featuredVideo.value.url.includes("vimeo");

  isVimeoVideo.value = isVimeo

  if (isVimeo) {
    initializeVimeo();
  } else {
    const videoID = featuredVideo.value.url.split("/")[4];
    initializeYoutube(videoID);
  }
};

const initializeVimeo = () => {
  const iframe = document.querySelector("#featured-video");
  const player = new Vimeo.Player(iframe);

  player.on("play", function () {
    markFeaturedVideoAsPlayed();
  });

  player.on("ended", function () {
    markFeaturedVideoAsWatched();
  });
};

const initializeYoutube = (videoID) => {
  new YT.Player("youtube-player", {
    height: "100%",
    width: "100%",
    videoId: videoID,
    playerVars: {
      // playsinline: 1,
    },
    events: {
      onStateChange: onPlayerStateChange,
    },
  });

  var done = false;

  function onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.PLAYING && !done) {
      markFeaturedVideoAsPlayed();
    }
    if (event.data == YT.PlayerState.ENDED) {
      markFeaturedVideoAsWatched();
    }
  }
};

const markFeaturedVideoAsPlayed = () => {
  let params = {
    featuredVideoID: featuredVideo.value.id,
  };
  let url = "/api/played-featured-video";

  axios.post(url, params).catch((error) => {
    Bugsnag.notify(error);
  });
};

const markFeaturedVideoAsWatched = () => {
  let params = {
    featuredVideoID: featuredVideo.value.id,
  };
  let url = "/api/watched-featured-video";

  axios.post(url, params).catch((error) => {
    Bugsnag.notify(error);
  });
};

onMounted(() => {
  setTimeout(async () => {
    await getLatestFeaturedVideo();
  }, 2000);
});
</script>
