import { defineStore } from "pinia";
import { useAuthStore } from "./auth.store";

export const useLabelsStore = defineStore("useLabelsStore", {
  getters: {
    labels() {
      return useAuthStore().user.dealer.labels;
    },
  },
});
