import dayjs from "dayjs";

export const dateFormat = (date, format = "ddd, MMM D, YYYY h:mm A") => {
  if (date === "No date" || !date) {
    return " No Assigned date";
  }
  return dayjs(date).format(format);
};

export const formatDateRange = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const today = dayjs();

  if (start.isSame(today, "day") && end.isSame(today, "day")) {
    return "Today";
  }

  if (start.isSame(end, "month")) {
    if (start.isSame(end, "day")) {
      return start.format("D MMM");
    } else {
      return `${start.format("D")} - ${end.format("D MMM")}`;
    }
  } else {
    if (start.isSame(today, "day")) {
      return `Today - ${end.format("D MMM")}`;
    } else {
      return `${start.format("D MMM")} - ${end.format("D MMM")}`;
    }
  }
};

export const formatDateToday = (date) => {
  if (!date) {
    return "";
  }

  const today = dayjs();
  const start = dayjs(date);
  if (today.isSame(start, "day")) {
    return "Today";
  } else {
    return start.format("DD MMM");
  }
}

export const formatDateForCalendar = (date, format = "YYYY-MM-DD") => {
  if (date === "No date" || !date) {
    return " No Assigned date";
  }
  return dayjs(date).format(format);
};

export const formatTimeForCalendar = (date, format = "HH:mm") => {
  if (date === "No date" || !date) {
    return " No Assigned date";
  }
  return dayjs(date).format(format);
};
