import axios from "axios";
import { useAuthStore } from "@/stores";
import { isEmpty as _isEmpty } from "lodash-es";

export default {
  createAxiosInstance() {
    const authStore = useAuthStore();

    let configs = {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      withCredentials: true,
    };

    if (!_isEmpty(authStore.$state.token)) {
      configs = Object.assign(configs, {
        Authorization: `Bearer ${authStore.$state.token}`,
      });
    }

    return axios.create(configs);
  },
};
