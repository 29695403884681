<script setup>
import { ref, inject, watch, defineProps, defineEmits, computed,onMounted } from "vue";
import ECombobox from "@/components/element-components/ECombobox";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
  Switch,
} from "@headlessui/vue";

import { forEach as _forEach } from "lodash-es";

import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPlugFileValidateSize from "filepond-plugin-file-validate-size";
import { VButton } from "revdojo-vue-components";
import NotificationDialog from "@/components/partials/NotificationDialog.vue";
import { store } from "@/signedUrl";
import { debounce as _debounce } from "lodash-es";
import Bugsnag from "@bugsnag/js";
import EventBus from "@/plugins/eventBus";

import { JoditEditor } from "jodit-vue3";
import { Jodit } from "jodit";
import "jodit/build/jodit.min.css";
import { isVimeoAvailable, isYoutubeAvailable } from "@/helpers/index";
import eventBus from "@/plugins/eventBus";
import { notificationMessage } from "@/helpers/error_message";
import { useAuthStore } from "@/stores";
import { getRoles } from "@/composable/users/getRoles";

const props = defineProps({
  isCreateUnitDialogOpen: Boolean,
  uploadContentModule: Object,
  courseId: Number,
});

const emit = defineEmits(["closeCreateUnitDialog"]);
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPlugFileValidateSize
);
const roles = getRoles();
const authStore = useAuthStore();
const dealers = ref([]);
const dealersLoading = ref(true)
const axios = inject("axios");
const uploadedScormFiles = ref(0)
const totalFiles = ref(0)
const pond = ref(null);
const showNotificationDialog = ref(false);
const myFiles = ref([]);
const isFile = ref(null);
const isLoading = ref(false);
const isDisabled = ref(false);
const seriesId = ref(null);
const moduleId = ref(null);
const courseId = ref(null);
const curriculumId = ref(null);
const url = ref("");
const unit = ref({
  name: "",
  content: "",
  content_file: null,
  textMode: false,
  isDraft: false,
  description: "",
  tags: [],
  videoDuration: 0,
  quiz_id: null,
  youtube_url: null,
  youtube_id: null,
  urlSource: null,
  fileUpload: null,
  fileVideoURL: null,
  isScorm: false,
  dealerId: null
});

const errors = ref({
  name: { fieldName: "name", error: null },
  content: { fieldName: "content", error: null },
  content_file: { fieldName: "content_file", error: null },
  description: { fieldName: "description", error: null },
  youtube_url: { fieldName: "youtube_url", error: null },
  urlSource: { fieldName: "urlSource", error: null },
  fileUpload: { fieldName: "fileUpload", error: null },
});

const options = ref({
  textIcons: false,
  iframe: false,
  iframeStyle: "*,.jodit_wysiwyg {color:red;}",
  height: "auto",
  minHeight: 160,
  maxHeight: 200,
  defaultMode: Jodit.MODE_WYSIWYG,
  imageDefaultWidth: "100%",
  observer: {
    timeout: 100,
  },
  commandToHotkeys: {
    openreplacedialog: "ctrl+p",
  },
  disablePlugins: ["image", "file", "video"],
  uploader: {
    url: "none",
  },
  defaultActionOnPaste: "insert_only_text",
  toolbar: false,
  showXPathInStatusbar: false,
  placeholder: "To upload plain text content, start writing here...",
});

const onSubmit = () => {
  isLoading.value = true;
  if (!isValid()) {
    isLoading.value = false;
    return;
  }

  if (isFile.value) {
    pond.value.processFiles();
  } else {
    publish();
  }
};
const handleFilePondRemoveFiles = () => {
  isFile.value = null;
};
const handleFilePondUpdateFiles = async () => {
  const files = pond.value.getFiles();

  totalFiles.value = files.length

  if(unit.value.isScorm) {
    isFile.value = pond.value.getFile() !== null;

    return
  }

  const loadVideo = (file) =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject("Invalid video. Please select a video file.");
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });

  const file = getUploadFile();

  if (!unit.value.textMode) {
    if (file) {
      const video = await loadVideo(file);
      const result = new Date(video.duration * 1000)
        .toISOString()
        .slice(11, 19);
      unit.value.videoDuration = result;
    } else {
      unit.value.videoDuration = 0;
    }
  }

  isFile.value = pond.value.getFile() !== null;
};
const handleFilePondInit = () => {
  isFile.value = pond.value.getFile() !== null;
};
const uploadFile = async (file, metadata, load, error, progress, abort) => {
  unit.value.folder = !unit.value.textMode
    ? "unit_videos"
    : "unit_content_file";

  try {
    const result = await store(file);

    if (!result?.key) {
      isLoading.value = false;
      error();

      return;
    }

    if (!unit.value.textMode) {
      unit.value.fileVideoURL = result.key;
    } else {
      unit.value.content_file = result.key;
    }

    if(unit.value.isScorm) {
      await uploadScorm()
    }else{
      await publish();
    }

    load(file);
  } catch {
    isLoading.value = false;
    error();
  }

  return {
    abort: () => {
      abort();
    },
  };
};
const closeModal = () => {
  unit.value = {
    name: "",
    content: "",
    content_file: null,
    textMode: false,
    isDraft: false,
    description: "",
    tags: [],
    videoDuration: 0,
    quiz_id: null,
    youtube_url: null,
    youtube_id: null,
    urlSource: null,
    fileUpload: null,
    fileVideoURL: null,
    isScorm: false,
    dealerId: null
  }

  emit("closeCreateUnitDialog");
};
const getUploadFile = () => {
  try {
    const file = pond.value.getFile().file;
    return file;
  } catch (error) {
    return null;
  }
};
const isValid = () => {
  let valid = true;

  errors.value = {
    name: { fieldName: "name", error: null },
    content: { fieldName: "content", error: null },
    content_file: { fieldName: "content_file", error: null },
    description: { fieldName: "description", error: null },
    youtube_url: { fieldName: "youtube_url", error: null },
    urlSource: { fieldName: "urlSource", error: null },
    fileUpload: { fieldName: "fileUpload", error: null },
  };

  if (!unit.value.name?.trim().length && ! unit.value.isScorm) {
    valid = false;
    errors.value.name.error = "The name field is required.";
  }

  if (unit.value?.name?.trim()?.length < 2 && ! unit.value.isScorm) {
    valid = false;
    errors.value.name.error = "There must be at least two characters.";
  }

  if (unit.value.textMode) {
    if (!unit.value.content?.length && !isFile.value) {
      valid = false;
      errors.value.content.error = "Content is required";
    }
  } else {
    if (!isFile.value && !url.value.length && !unit.value.isScorm)
      if (!isFile.value && !url.value.length) {
        errors.value.fileUpload.error = "File is required";
        errors.value.youtube_url.error = "URL is required";
        valid = false;
      }
  }
  return valid;
};
const publish = async () => {
  isLoading.value = true;

  const textData = new FormData();
  textData.append("name", unit.value.name);
  textData.append("content", unit.value.content);
  textData.append("file", unit.value.content_file);
  textData.append("textMode", unit.value.textMode);
  textData.append("is_draft", unit.value.isDraft);
  textData.append("description", unit.value.description);
  textData.append("series_id", seriesId.value);
  textData.append(
    "module_id",
    props.uploadContentModule ? props.uploadContentModule.id : moduleId.value
  );
  textData.append(
    "course_id",
    props.uploadContentModule
      ? props.uploadContentModule.course_id
      : courseId.value
  );
  textData.append(
    "curriculum_id",
    props.uploadContentModule
      ? props.uploadContentModule.curriculum_id
      : curriculumId.value
  );

  _forEach(unit.value.tags, (tag) => {
    typeof tag === "object"
      ? textData.append("tags[]", tag.id)
      : textData.append("tags[]", tag);
  });
  if (unit.value.quiz_id) {
    textData.append("quiz_id", unit.value.quiz_id.id);
  }

  const videoData = new FormData();

  if (unit.value.fileVideoURL) {
    videoData.append("fileVideoURL", unit.value.fileVideoURL);
  }

  videoData.append("name", unit.value.name);
  videoData.append("is_draft", unit.value.isDraft);
  videoData.append("youtube_url", url.value);
  videoData.append("youtube_id", unit.value.youtube_id);
  videoData.append("urlSource", unit.value.urlSource);
  videoData.append("description", unit.value.description);
  videoData.append("series_id", seriesId.value);
  videoData.append(
    "module_id",
    props.uploadContentModule ? props.uploadContentModule.id : moduleId.value
  );
  videoData.append(
    "course_id",
    props.uploadContentModule
      ? props.uploadContentModule.course_id
      : courseId.value
  );
  videoData.append(
    "curriculum_id",
    props.uploadContentModule
      ? props.uploadContentModule.curriculum_id
      : curriculumId.value
  );
  videoData.append("video_duration", unit.value.videoDuration);
  _forEach(unit.value.tags, (tag) => {
    typeof tag === "object"
      ? videoData.append("tags[]", tag.id)
      : videoData.append("tags[]", tag);
  });
  if (unit.value.quiz_id) {
    videoData.append("quiz_id", unit.value.quiz_id.id);
  }

  const payload = unit.value.textMode ? textData : videoData;

  axios
    .post("/api/client/lms/publish/unit", payload)
    .then(({ data }) => {
      EventBus.$emit("SAVING_UNIT");

      showNotificationDialog.value = true;

      let url = `/edit/unit/${data.unit.id}`;
      if (props.courseId) {
        url += `/course/${props.courseId}`;
      }
      window.open(url, "_self");
    })
    .catch((error) => {
      Bugsnag.notify(error);
      isLoading.value = false;
      if (error.response?.status === 500) {
        EventBus.$emit(
          "CREATE_NOTIFICATION",
          notificationMessage[500],
          "server_error",
          6500
        );
        return;
      }
      eventBus.$emit("NOTIFICATION_DIALOG", {
        show: true,
        message: error?.response?.data?.message,
      });
    });
};

const uploadScorm = async() => {
  const payload = new FormData();

  if(! unit.value.fileVideoURL) {
    return
  }

  uploadedScormFiles.value += 1

  payload.append("fileVideoURL", unit.value.fileVideoURL);
  payload.append('dealerId', unit.value.dealerId)
  axios
    .post("/api/client/lms/upload/scorm", payload)
    .then(() => {
      if(uploadedScormFiles.value === totalFiles.value) {
        uploadedScormFiles.value = 0
        totalFiles.value = 0
        isLoading.value = false;

        eventBus.$emit("NOTIFICATION_DIALOG", {
          show: true,
          message: "Successfully uploaded scorm files",
        });

        closeModal()
      }

      // showNotificationDialog.value = true;
    })
    .catch((error) => {
      Bugsnag.notify(error);
      isLoading.value = false;
      if (error.response?.status === 500) {
        EventBus.$emit(
          "CREATE_NOTIFICATION",
          notificationMessage[500],
          "server_error",
          6500
        );
        return;
      }
      eventBus.$emit("NOTIFICATION_DIALOG", {
        show: true,
        message: error?.response?.data?.message,
      });
    });
}


const getScormDealers = async () => {
  dealersLoading.value = true

  axios
    .get("/api/v3/scorm/dealers")
    .then(({ data }) => {
      dealers.value = data.map(({ id, name }) => ({
        label: name,
        value: id,
      }));

      dealersLoading.value = false
    })
    .catch((error) => {
      Bugsnag.notify(error);
      dealersLoading.value = false
    });
}

const getIdFromVimeoURL = (url) => {
  let videoURL =
    /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([0-9]{6,11})\?h=([a-zA-Z0-9]*)[?]?.*/.exec(
      url
    );

  if (videoURL == null) {
    videoURL =
      /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([0-9]{6,11})\/?([a-zA-Z0-9]*)[?]?.*/.exec(
        url
      );
  }

  try {
    if (videoURL[6]) {
      return videoURL[5] + "?h=" + videoURL[6];
    }

    return videoURL[5];
  } catch {
    return false;
  }
};

const youtubeParser = (url) => {
  try {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    const youtubeId = match && match[7].length == 11 ? match[7] : false;

    return youtubeId;
  } catch {
    return false;
  }
};

const fetchVideoId = _debounce(async (value) => {
  const url = value;

  const youtubeId = youtubeParser(url);
  const vimeoId = getIdFromVimeoURL(url);

  errors.value.youtube_url.error = "";

  if (youtubeId) {
    isDisabled.value = true;

    const isAvailable = await isYoutubeAvailable(youtubeId);

    if (isAvailable) {
      isDisabled.value = false;
      unit.value.youtube_id = youtubeId;
      unit.value.urlSource = "youtube";
      errors.value.youtube_url.error = "";
      return;
    }

    errors.value.youtube_url.error =
      "The video you're trying to upload is possibly set as private or has already been deleted.";
    return;
  }

  if (vimeoId) {
    isDisabled.value = true;

    const isAvailable = await isVimeoAvailable(vimeoId);

    if (isAvailable) {
      isDisabled.value = false;
      unit.value.youtube_id = vimeoId;
      unit.value.urlSource = "vimeo";
      errors.value.youtube_url.error = "";
      return;
    }

    errors.value.youtube_url.error =
      "The video you're trying to upload is possibly set as private or has already been deleted.";
    return;
  }

  unit.value.youtube_id = null;
  unit.value.urlSource = null;

  errors.value.youtube_url.error = "Invalid Link or URL";
}, 100);


const acceptedFileTypes = computed(() => {
  return unit.value.isScorm ? "application/zip, application/x-zip-compressed, multipart/x-zip, application/x-winzip" : "video/mp4, video/x-matroska, video/quicktime";
});

const fondLabel = computed(() => {
  return unit.value.isScorm ? "Drop your video file here or click to browse your computer (Accepted File : zip)" : "Drop your video file here or click to browse your computer (Accepted File : mp4, mov, mkv)";
});

const allowMultiple = computed(() =>
  unit.value.isScorm ? true : false
);

const isEnabledScormUpload = computed(() => authStore.user.is_enabled_scorm_upload)
const isScormAccess = computed(() => authStore.user.scorm_access);

const isSuperAdmin = computed(() => roles.isSuperAdmin.value);
const isCompanyAdmin = computed(() => roles.isAccountManager.value);

watch(url, (value) => {
  if (value != undefined) {
    fetchVideoId(value);

    const clearURL = _debounce(() => {
      if (errors.value.youtube_url.error) {
        url.value = "";
      }
    }, 100);

    clearURL();
  }
});

onMounted(async () => {
  await authStore.getAuth();
  getScormDealers()
});

</script>

<template>
  <TransitionRoot appear :show="props.isCreateUnitDialogOpen" as="template">
    <Dialog as="div" class="relative z-[100] w-4/4 max-h-[500px]">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                Upload {{ !unit.textMode ? " Video" : " Text Content" }}
              </DialogTitle>
              <div class="mt-5">
                <form>
                  <div class="max-h-[350px] overflow-y-auto pr-2 pl-2">
                    <div
                      class="flex flex-col lg:flex-row md:flex-row justify-between items-center"
                    >
                      <div v-if="!unit.isScorm">
                        <input
                          v-model="unit.name"
                          type="text"
                          placeholder="Unit Name"
                          class="mr-3block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm"
                          required
                          :disabled="isLoading"
                        />
                        <p
                          v-if="errors.name"
                          class="mt-2 text-red-500 text-xs italic"
                        >
                          {{ errors.name.error }}
                        </p>
                      </div>

                      <div v-if="!unit.isScorm" class="flex">
                        <Switch
                          v-model="unit.textMode"
                          :class="
                            unit.textMode
                              ? 'bg-untitled-gray-800'
                              : 'bg-untitled-gray-400'
                          "
                          class="relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                          :disabled="isLoading"
                        >
                          <span
                            aria-hidden="true"
                            :class="
                              unit.textMode ? 'translate-x-9' : 'translate-x-0'
                            "
                            class="pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
                          />
                        </Switch>
                        <small class="ml-2"
                          >Switch to
                          {{
                            unit.textMode ? " Video Upload" : " Text / PDF"
                          }}</small
                        >
                      </div>
                    </div>

                    <div v-if="!unit.textMode">
                      <div
                        v-if="!unit.youtube_id"
                        class="mt-5 cursor-pointer p-0 text-sm"
                      >
                        <file-pond
                          className="cursor-pointer text-sm"
                          :label-idle="fondLabel"
                          ref="pond"
                          credits="false"
                          allow-multiple="false"
                          allowFileTypeValidation="true"
                          allowFileSizeValidation="true"
                          fileValidateTypeDetectType="true"
                          maxFileSize="200MB"
                          allowRemove="true"
                          allowProcess="false"
                          checkValidity="false"
                          instant-upload="false"
                          v-bind:files="myFiles"
                          :beforeRemoveFile="handleFilePondRemoveFiles"
                          v-on:updatefiles="handleFilePondUpdateFiles"
                          v-on:init="handleFilePondInit"
                          :accepted-file-types="acceptedFileTypes"
                          :allowMultiple="allowMultiple"
                          :server="{
                            process: (
                              fieldName,
                              file,
                              metadata,
                              load,
                              error,
                              progress,
                              abort
                            ) => {
                              uploadFile(
                                file,
                                metadata,
                                load,
                                error,
                                progress,
                                abort
                              );
                            },
                          }"
                        />
                      </div>

                      <div
                        v-if="!isFile && !unit.isScorm"
                        class="mt-16 mr-3 mb-5 flex justify-center flex-col"
                      >
                        <input
                          v-model="url"
                          type="text"
                          placeholder="Or paste a YouTube/Vimeo link"
                          class="text-sm lg:text-md block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm"
                          :disabled="isLoading"
                        />
                        <p
                          v-if="errors.youtube_url"
                          class="mt-2 text-red-500 text-xs italic"
                        >
                          {{ errors.youtube_url.error }}
                        </p>
                      </div>
                    </div>
                    <div class="my-5" v-if="unit.isScorm && isSuperAdmin">
                      <e-combobox
                        v-model="unit.dealerId"
                        :options="dealers"
                        option-name="label"
                        value-key="value"
                        placeholder="Select Company"
                        :multiple="false"
                        :filterable="true"
                        :clearable="true"
                        :loading="dealersLoading"
                      />
                    </div>
                    <div v-if="!unit.textMode && isScormAccess && (isSuperAdmin || isCompanyAdmin) && isEnabledScormUpload" class="flex">
                      <Switch
                        v-model="unit.isScorm"
                        :class="
                          unit.isScorm
                            ? 'bg-untitled-gray-800'
                            : 'bg-untitled-gray-400'
                        "
                        class="relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                        :disabled="isLoading"
                      >
                        <span
                          aria-hidden="true"
                          :class="
                            unit.isScorm ? 'translate-x-9' : 'translate-x-0'
                          "
                          class="pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
                        />
                      </Switch>
                      <small class="ml-2"
                        >Switch to
                        {{
                          unit.isScorm ? "Unit" : "Pryor Content"
                        }}</small
                      >
                    </div>

                    <div v-if="unit.textMode">
                      <div class="mt-2">
                        <file-pond
                          className="cursor-pointer text-sm"
                          label-idle="Upload PDF File"
                          ref="pond"
                          credits="false"
                          allow-multiple="false"
                          allowFileTypeValidation="true"
                          allowFileSizeValidation="true"
                          fileValidateTypeDetectType="true"
                          max-files="1"
                          maxFileSize="50MB"
                          allowRemove="true"
                          allowProcess="false"
                          checkValidity="false"
                          instant-upload="false"
                          v-bind:files="myFiles"
                          :beforeRemoveFile="handleFilePondRemoveFiles"
                          v-on:updatefiles="handleFilePondUpdateFiles"
                          v-on:init="handleFilePondInit"
                          accepted-file-types="application/pdf"
                          :disabled="isLoading"
                          :server="{
                            process: (
                              fieldName,
                              file,
                              metadata,
                              load,
                              error,
                              progress,
                              abort
                            ) => {
                              uploadFile(
                                file,
                                metadata,
                                load,
                                error,
                                progress,
                                abort
                              );
                            },
                          }"
                        />
                      </div>
                      <div v-if="!isFile" class="mt-3">
                        <jodit-editor
                          v-model="unit.content"
                          :editorOptions="options"
                          :disabled="isLoading"
                        ></jodit-editor>
                        <p
                          v-if="errors.content"
                          class="mt-2 text-red-500 text-xs italic"
                        >
                          {{ errors.content.error }}
                        </p>
                      </div>
                    </div>

                    <div v-if="!unit.isScorm" class="mt-2">
                      <div class="flex justify-end">
                        <Switch
                          v-model="unit.isDraft"
                          :class="
                            unit.isDraft
                              ? 'bg-untitled-gray-800'
                              : 'bg-untitled-gray-400'
                          "
                          class="relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                          :disabled="isLoading"
                        >
                          <span
                            aria-hidden="true"
                            :class="
                              unit.isDraft ? 'translate-x-9' : 'translate-x-0'
                            "
                            class="pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
                          />
                        </Switch>
                        <small class="ml-2">Draft</small>
                      </div>
                    </div>
                  </div>
                  <div class="mt-10 flex justify-end">
                    <VButton
                      class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
                      :loading="isLoading"
                      :disabled="isLoading || isDisabled || (unit.isScorm && totalFiles === 0)"
                      @click.prevent="onSubmit"
                    >
                      Save
                    </VButton>
                    <VButton
                      type="button"
                      class="ml-2 inline-flex w-full justify-center rounded-md border border-untitled-gray-300 bg-untitled-gray-300 px-4 py-2 text-base font-medium text-untitled-gray-100 shadow-sm hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      @click="closeModal"
                      :disabled="isLoading"
                    >
                      Cancel
                    </VButton>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <NotificationDialog
    class
    v-if="showNotificationDialog"
    @close="showNotificationDialog = false"
  />
</template>
<style>
.filepond--drop-label.filepond--drop-label label {
  cursor: pointer;
}
.jodit-status-bar {
  display: none;
}
.jodit-add-new-line.jodit-add-new-line_after {
  display: none;
}
</style>
