import { eventEndpoints } from "@/endpoints/event";
import EventModel from "@/model/EventModel";
import { useHandleErrorStatus } from "../useHandleErrorStatus";
import { ref } from "vue";
import eventBus from "@/plugins/eventBus";
import { notificationMessage } from "@/helpers/error_message";

export const postEvent = async (event) => {
  const errorValue = ref(null);
  const { isError, error, loading, data } = await new EventModel(
    eventEndpoints.resource
  ).post(event);
  if (isError && error) {
    const { data: errorResponse } = useHandleErrorStatus(error);
    errorValue.value = errorResponse;
  }

  if (error && error.response.status === 500) {
    eventBus.$emit(
      "CREATE_NOTIFICATION",
      notificationMessage[500],
      "server_error",
      6500
    );
  }

  return {
    data,
    errorValue,
    loading,
    isError,
  };
};
