import Model from "./Model";

class EventModel extends Model {
  constructor(url = null) {
    super(url);
  }

  // custom methods
}

export default EventModel;
