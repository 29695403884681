const LmsSettingsModule = () => import("./EventsModule.vue");
const baseUrl = "/events";

const moduleRoute = {
  path: baseUrl,
  component: LmsSettingsModule,
  props: true,
  children: [
    {
      path: "",
      name: "events",
      component: () => import("./views/EventsPage.vue"),
      props: true,
      meta: {
        title: "Events",
        auth: true,
      },
    },
    {
      path: `${baseUrl}/create`,
      name: "create-event-page",
      component: () => import("./views/EventFormPage.vue"),
      props: true,
      meta: {
        title: "Create Event",
        auth: true,
      },
    },
    {
      path: `${baseUrl}/:id/:view?`,
      name: "event-detail-page",
      component: () => import("./views/ViewEventPage.vue"),
      props: true,
      meta: {
        title: "View Event",
      },
    },
    {
      path: `${baseUrl}/:id/edit`,
      name: "edit-event-page",
      component: () => import("./views/EventFormPage.vue"),
      props: true,
      meta: {
        title: "Edit Event",
      },
    },
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};
