const CustomPlaylist = () => import("./CustomPlaylist.vue");

const baseRoute = "/custom-playlist";

const moduleRoute = {
  path: baseRoute,
  component: CustomPlaylist,
  props: true,
  children: [
    {
      path: "",
      name: "Custom Playlist Page",
      component: () => import("./views/CustomPlaylistPage.vue"),
      props: true,
      meta: {
        title: "Custom Playlist",
        auth: true,
      },
    },
    {
      path: `${baseRoute}/create`,
      name: "Create Custom Playlist",
      component: () => import("./views/CreateCustomPlaylistPage.vue"),
      props: true,
      meta: {
        title: "Create Custom Playlist",
        auth: true,
      },
    },
    {
      path: `${baseRoute}/:id/edit`,
      name: "Edit Custom Playlist",
      component: () => import("./views/EditCustomPlaylistPage.vue"),
      props: true,
      meta: {
        title: "Edit Custom Playlist",
        auth: true,
      },
    },
    {
      path: `${baseRoute}/:id/:playlistId`,
      name: "Custom Playlist Unit Page",
      component: () => import("./views/CustomPlaylistUnitPage.vue"),
      props: true,
      meta: {
        title: "Video Rating Page",
        auth: true,
      },
    },
    {
      path: `${baseRoute}/:id/votes`,
      name: "Custom Playlist View Votes Page",
      component: () => import("./views/CustomPlaylistVotingResultPage.vue"),
      props: true,
      meta: {
        title: "View Votes",
        auth: true,
      },
    },
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};
