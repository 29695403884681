import Bugsnag from "@bugsnag/js";
import { ref } from "vue";
import { router } from "@/router";
import eventBus from "@/plugins/eventBus";
import { notificationMessage } from "@/helpers/error_message";
// import { markRaw } from "vue";

export const useHandleErrorStatus = (error) => {
  const errorObject = ref(null);
  const errorResponse = error;
  switch (errorResponse.response?.status) {
    case 422:
      errorObject.value = error.response.data;
      break;

    case 401:
      router.push({ name: "PageNotFound" });
      break;

    case 404:
      router.push({ name: "PageNotFound" });
      break;

    case 500:
      eventBus.$emit(
        "CREATE_NOTIFICATION",
        notificationMessage[500],
        "server_error",
        6500
      );
      break;

    default:
      Bugsnag.notify(error);
      break;
  }

  return {
    data: errorObject.value,
    errorResponse,
  };
};
