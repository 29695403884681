<template>
  <div>
    <Menu as="div" class="relative inline-block text-right cols-span-1 items-end"
      :id="`Group-Dropdown-ellipsis-button-${group.id}`">
      <div>
        <MenuButton class="inline-flex justify-center rounded-md text-sm font-medium text-gray-700">
          <DotsVerticalIcon class="h-6 w-6" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <MenuItems
          class="absolute right-0 z-20 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div class="py-1 divide-y divide-untitled-gray-200">
            <MenuItem v-slot="{ active }" v-if="route.params.id != group.id">
            <a @click="handleClickVisit" class="cursor-pointer" :id="`Group-Dropdown-Visit-button-${group.id}`" :class="[
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'block px-4 py-2 text-sm',
            ]">Visit Group</a>
            </MenuItem>
            <MenuItem v-slot="{ active }" v-if="isAdmin">
            <a class="cursor-pointer" @click="handleClickEdit" :id="`Group-Dropdown-Edit-button-${group.id}`" :class="[
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'block px-4 py-2 text-sm',
            ]">Edit Group</a>
            </MenuItem>
            <MenuItem v-slot="{ active }" v-if="isAdmin">
            <a class="cursor-pointer" @click="handleClickDelete" :id="`Group-Dropdown-Delete-button-${group.id}`" :class="[
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'block px-4 py-2 text-sm',
            ]">Delete Group</a>
            </MenuItem>
            <MenuItem v-if="isAdmin" v-slot="{ active }">
            <a @click="handleClickAdd" class="cursor-pointer" :id="`Group-Dropdown-Add-Members-button-${group.id}`"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
              ]">Add members</a>
            </MenuItem>
            <MenuItem v-slot="{ active }">
            <a @click="handleClickView" class="cursor-pointer" :id="`Group-Dropdown-View-Members-button-${group.id}`"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
              ]">View members</a>
            </MenuItem>
            <MenuItem v-if="isAdmin && (route.name !== 'group-assignment' || route.params.id != group.id)"
              v-slot="{ active }">
            <a @click="handleClickViewGroupAssignment" class="cursor-pointer"
              :id="`Group-Dropdown-View-Group-Assignment-button-${group.id}`" :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
              ]">View Group Assignment</a>
            </MenuItem>
            <MenuItem v-if="isAdmin && route.name === 'group-assignment' && route.params.id == group.id"
              v-slot="{ active }">
            <a @click="handleClickVisit" class="cursor-pointer"
              :id="`Group-Dropdown-View-Group-Assignment-button-${group.id}`" :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
              ]">View Member Assignment</a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup>
import { DotsVerticalIcon } from "@heroicons/vue/outline";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { defineEmits, defineProps, computed } from "vue";
import { useRouter, useRoute } from "vue-router";

const props = defineProps({
  group: {
    type: Object,
  },
});

const isAdmin = computed(() => props.group.is_admin);
const route = useRoute();
const router = useRouter();

const emit = defineEmits(["edit", "delete", "add", "view", "assignment"]);

const handleClickEdit = () => {
  emit("edit");
};

const handleClickDelete = () => {
  emit("delete");
};

const handleClickAdd = () => {
  emit("add");
};

const handleClickVisit = () => {
  router.push({
    name: "group",
    params: {
      id: props.group.id,
    },
  });
};

const handleClickView = () => {
  emit("view");
};

const handleClickViewGroupAssignment = () => {
  emit("assignment");
};
</script>
