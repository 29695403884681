export const useCategoryApi = {
  get: () => {
    return "v3/courses/categories";
  },
  put: (id) => {
    return `v3/courses/categories/${id}`;
  },
  delete: (id) => {
    return `v3/courses/categories/delete/${id}`;
  },
  post: () => {
    return `v3/courses/categories`;
  },
};
