import { dateFormat } from "@/helpers/date_formatter";
import { extractTitleFromLink } from "@/helpers/link_validator";
import { useAuthStore } from "@/stores";
import { computed } from "vue";

const urlRegex = /(?:https?|ftp):\/\/[\w/\-?=%.]+\.[\w/\-?=%.]+/g;

export const modifiedDescription = (reminder) => {
  const authUser = useAuthStore();
  const userProfile = computed(() => authUser.user);

  const getUsers = computed(() => {
    if (reminder.users && reminder.users.length) {
      return `${reminder.users[0].name} ${
        reminder.users.length >= 2
          ? `and ${reminder.users.length - 1} more `
          : ""
      } `;
    }

    return null;
  });

  let title = reminder.name;

  const descriptionToChange = reminder.description;
  if (!title) {
    return descriptionToChange;
  }

  let description = descriptionToChange.split(".");
  let newSentence = null;
  if (reminder.author_id == userProfile.value.id && reminder.type !== "plain") {
    newSentence = `${title} has been assigned to ${
      getUsers.value
    }by you. The expected completion date is ${dateFormat(
      reminder.assignedDateTime
    )}`;
  } else {
    newSentence = `${title.trim()} has been assigned to you by ${
      reminder.author.name
    }. Please complete it before ${dateFormat(reminder.assignedDateTime)}`;
  }
  description[0] = newSentence;
  description[1] = null;
  let newDescription = description.join(".").replace("..", ".");
  return newDescription;
};

export const truncateDescription = (descriptionData, size = 400) => {
  if (descriptionData && descriptionData.length > size) {
    return descriptionData.slice(0, size) + "...";
  }

  return descriptionData;
};

export const formatText = (text, title) => {
  return truncateDescription(
    text.replace(urlRegex, (match) => {
      let newTitle = "";
      if (title === "plain") {
        newTitle = extractTitleFromLink(match);
      }
      return `<a href="${match}" target="_blank" class="text-blue-700 decoration-solid shadow-md px-1 py-1 hover:bg-untitled-gray-200">${
        title === "plain" ? newTitle : `Click here to view assigned ${title}`
      }</a>`;
    }),
    500
  );
};
