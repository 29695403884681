import { ref } from "vue";
import { defineStore } from "pinia";
import { uniqueId as _uniqueId } from "lodash-es";

export const useNotificationStore = defineStore("notification", () => {
  const notifications = ref([]);

  const addNotification = (
    message,
    notificationType = null,
    timeout = null,
    link = null,
  ) => {
    const notification = {
      content: message,
      link: link,
      id: _uniqueId("notification_"),
    };

    if (timeout) {
      notification.timeout = timeout;
    }

    if (notificationType) {
      notification.notificationType = notificationType;
    }

    notifications.value.push(notification);
    setTimeout(() => {
      removeNotification(notification.id);
    }, timeout ?? 5000);
  };

  const removeNotification = (id) => {
    notifications.value = notifications.value.filter(
      (notification) => notification.id !== id
    );
  };

  const closeNotification = (id) => {
    const index = notifications.value.findIndex(
      (notification) => notification.id === id
    );
    notifications.value.splice(index, 1);
  };

  return {
    notifications,
    addNotification,
    closeNotification,
  };
});
