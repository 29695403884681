import Bugsnag from "@bugsnag/js";
import { ref, inject } from "vue";

export const getCompanies = (limit, page, filter) => {
  const companies = ref([]);
  const loadingCompanies = ref(false);
  const axios = inject("axios");

  const fetchMainCompanies = async () => {
    loadingCompanies.value = true;
    const response = await axios.get("/api/fetchMainCompanies", {
      params: { limit, page, filter },
    });
    companies.value = response.data;
    loadingCompanies.value = false;
  };

  return { companies, fetchMainCompanies, loadingCompanies };
};

export const getSubCompanies = () => {
  const subCompanies = ref([]);
  const axios = inject("axios");
  const loadingSubCompanies = ref(false);
  const fetchSubCompanies = async (dealerIds) => {
    loadingSubCompanies.value = true;
    const response = await axios.get("/api/fetchSubCompanies", {
      params: { dealerIds },
    });
    subCompanies.value = response.data;
    loadingSubCompanies.value = false;
  };

  return { subCompanies, fetchSubCompanies, loadingSubCompanies };
};

export const getCompaniesAndDealers = () => {
  const companies = ref([]);
  const dealers = ref([]);
  const axios = inject("axios");

  const load = async () => {
    await axios
      .get("/api/companies-and-dealers")
      .then(({ data }) => {
        dealers.value = data.data.dealers;
        companies.value = data.data.companies;
      })
      .catch((error) => {
        Bugsnag.notify(error);
      });
  };

  return {
    load,
    companies,
    dealers,
  };
};
