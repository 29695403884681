<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: "No data available",
  },
  height: {
    type: String,
    default: null,
    required: false,
  },
});

const title = computed(() => props.title);
const height = computed(() => props.height);
</script>
<template>
  <div
    :class="[
      height ?? 'h-96',
      'w-full flex justify-center items-center text-sm text-untitled-gray-600',
    ]"
    v-bind="$attrs"
  >
    {{ title }}
  </div>
</template>
