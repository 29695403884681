import { ref, inject } from "vue";

export const getUsers = () => {
  const users = ref([]);
  const axios = inject("axios");
  const loadingUsers = ref(false);

  const userList = async (params = null) => {
    loadingUsers.value = true;
    const response = await axios.get("/api/client/lms/fetchUsersByDealer", {
      params,
    });
    users.value = response.data;
    loadingUsers.value = false;
  };

  return { users, userList, loadingUsers };
};

export const getLeaders = () => {
  const leaders = ref([]);
  const axios = inject("axios");
  const loadingLeaders = ref(false);

  const leaderLists = async (companyIds = null, storeIds = null) => {
    loadingLeaders.value = true;
    const response = await axios.get("/api/v3/leaders", {
      params: {
        companyIds: companyIds,
        storeIds: storeIds,
      },
    });
    leaders.value = response.data;
    loadingLeaders.value = false;
  };

  return { leaders, leaderLists, loadingLeaders };
};

export const getGroups = () => {
  const groups = ref([]);
  const axios = inject("axios");
  const loadingGroups = ref(false);
  const groupList = async (dealer_ids, specific_dealer_ids ,group_admin_only = true) => {
    loadingGroups.value = true;
    const response = await axios.get("/api/groups", {
      params: { dealer_ids, specific_dealer_ids,group_admin_only },
    });
    groups.value = response.data;
    loadingGroups.value = false;
  };

  return { groups, groupList, loadingGroups };
};

export const getCompanies = () => {
  const companies = ref([]);
  const axios = inject("axios");
  const loadingCompanies = ref(false);
  const companyList = async () => {
    loadingCompanies.value = true;
    const response = await axios.get("/api/companies-and-dealers");
    companies.value = response.data?.data?.companies;
    loadingCompanies.value = false;
  };

  return { companies, companyList, loadingCompanies };
};

export const getDealers = () => {
  const dealers = ref([]);
  const axios = inject("axios");
  const loadingDealers = ref(false);
  const dealerList = async () => {
    loadingDealers.value = true;
    const response = await axios.get("/api/companies-and-dealers");
    dealers.value = response.data?.data?.dealers;
    loadingDealers.value = false;
  };

  return { dealers, dealerList, loadingDealers };
};
