const ScormCourse = () => import('./ScormCourse.vue');
const ScormCourseWatch = () => import('./views/ScormCourseWatch.vue');

const moduleRoute = {
    path: "/scorm",
    component: ScormCourse,
    props: true,
    children: [
        {
            path: ':id',
            component: ScormCourseWatch,
            name: 'ScormCourseWatch',
            props: true,
            meta: {
              title: "Course Watch",
              auth: true,
            },
        }
    ],
};

export default (router) => {
	router.addRoute(moduleRoute);
};
