export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertToPossessive = (string) => {
  const endsWithS = string.endsWith("s");
  return endsWithS ? string : `${string}'s`;
};

export const validateHtmlScriptString = (string) => {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;
  const scriptRegex = /<script[\s\S]*>[\s\S]*<\/script>/i;

  // Check if the string contains HTML tags or script
  return htmlRegex.test(string) || scriptRegex.test(string);
};
