<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-[100000] w-full">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-untitled-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 h-3/6">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="max-w-7xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                Assign Content
              </DialogTitle>
              <div class="mt-5 flex flex-col gap-5 overflow-y h-full">
                <div class="flex flex-col">
                  <v-multiple-combobox
                    v-model="selectedUsers"
                    :options="users"
                    option-name="name"
                    option-value="id"
                    placeholder="Start typing to search for users..."
                    :loading="loadingUsers"
                  />
                </div>
                <div class="flex flex-col mt-10">
                  <v-multiple-combobox
                    v-model="selectedGroups"
                    :options="groups"
                    option-name="name"
                    option-value="id"
                    placeholder="Start typing to search groups..."
                    :loading="loadingGroups"
                  />
                </div>

                <div class="flex flex-col mt-10">
                  <label
                    class="block text-sm font-medium text-untitled-gray-700"
                    >Due Date</label
                  >
                  <v-date-picker
                    v-model="dueDate"
                    enableTimePicker
                    :min-date="currentDate"
                    :is-24="false"
                    @change="fetchAssigned"
                  />
                </div>
                <div class="flex flex-row">
                  <button
                    type="button"
                    @click="handleSubmit"
                    class="relative grow justify-center inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
                  >
                    Save
                  </button>

                  <button
                    type="button"
                    @click="isOpen = false"
                    class="grow relative ml-2 inline-flex justify-center rounded-md border border-untitled-gray-300 bg-white px-4 py-2 text-base font-medium text-untitled-gray-700 shadow-sm hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, computed, onMounted, inject, defineEmits } from "vue";
import {
  TransitionChild,
  TransitionRoot,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { getUsers, getGroups } from "@/composable/users/getUsers";
import dayjs from "dayjs";
import EventBus from "@/plugins/eventBus";
import Bugsnag from "@bugsnag/js";
import { VMultipleCombobox, VDatePicker } from "revdojo-vue-components";

const isOpen = ref(false);
const playlistId = ref(null);
const selectedUsers = ref([]);
const selectedGroups = ref([]);
const dueDate = ref(null);
const dueTime = ref(null);
const axios = inject("axios");
const emit = defineEmits(["close", "assign"]);

EventBus.$on("OPEN_ASSIGN_CONTENT_PLAYLIST", (value) => {
  playlistId.value = value;
  isOpen.value = true;
});

const { userList, users, loadingUsers } = getUsers();
const { groupList, groups, loadingGroups } = getGroups();

onMounted(async () => {
  await userList();
  await groupList();
});

async function handleSubmit() {
  await axios
    .post("/api/v3/learning-paths/assign", assignedForm.value)
    .then(() => {
      isOpen.value = false;
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
}

const assignedForm = computed(() => {
  let due_time = dueTime.value ? dueTime.value : "9:00";
  let due_date = dayjs(dueDate.value + " " + due_time).format(
    "YYYY-MM-DD HH:mm:ss"
  );
  return {
    assigned_users: selectedUsers.value,
    assigned_groups: selectedGroups.value,
    due_date: due_date,
    playlist_id: playlistId.value,
  };
});

const fetchAssigned = () => {
  emit("assign", assignedForm.value);
};
</script>
