<template>
  <div class="flex flex-col lg:flex-row w-full lg:items-center lg:w-11/12">
    <ask-user-to-assign-dialog></ask-user-to-assign-dialog>
    <assign-learning-path-dialog></assign-learning-path-dialog>
    <div
      class="relative w-full lg:w-1/4 mb-4 lg:mb-0 lg:mr-3 flex flex-col gap-2 justify-start items-center"
    >
      <router-link
        :to="'/create/playlist'"
        class="inline-flex items-center rounded-md border border-transparent bg-untitled-gray-200 px-3 py-2 text-sm font-medium leading-4 text-untitled-gray-800 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-untitled-gray-300 focus:ring-offset-2 w-full text-center justify-center"
      >
        Create Playlist
      </router-link>
    </div>
    <div class="relative w-full lg:w-1/4 mb-4 lg:mb-0 lg:mr-3">
      <div class="absolute z-0 inset-0 m-auto mr-2 xl:mr-4 w-5 h-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon cursor-pointer icon-tabler icon-tabler-chevron-down"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#a0aec0"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </div>
      <GroupSelectInput
        v-model="sortBy"
        :options="sorts"
        label="name"
        value="value"
        :show-icon="true"
        :is-icon="true"
        :icon="AdjustmentsIcon"
        placeholder="Sort By"
        :clearable="true"
      />
    </div>
    <div class="relative w-full lg:w-1/4 mb-4 lg:mb-0 lg:mr-3">
      <div class="absolute z-0 inset-0 m-auto mr-2 xl:mr-4 w-5 h-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon cursor-pointer icon-tabler icon-tabler-chevron-down"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#a0aec0"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </div>
      <GroupSelectInput
        v-model="filterBy"
        :options="filters"
        label="name"
        value="value"
        :show-icon="true"
        :is-icon="true"
        :icon="FilterIcon"
        placeholder="Filter By"
        :clearable="true"
      />
    </div>
    <div class="relative w-full lg:w-1/4 mb-4 lg:mb-0 lg:mr-3">
      <div class="absolute z-0 inset-0 m-auto mr-2 xl:mr-4 w-5 h-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon cursor-pointer icon-tabler icon-tabler-chevron-down"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#a0aec0"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </div>
      <GroupSelectInput
        v-model="filterByPrivacy"
        :options="privacies"
        label="name"
        value="value"
        :show-icon="true"
        :is-icon="true"
        :icon="filterByPrivacy === 'public' ? LockOpenIcon : LockClosedIcon"
        placeholder="Filter Privacy"
        :clearable="true"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps } from "vue";
import AskUserToAssignDialog from "@/components/learning-paths/AskUserToAssignDialog.vue";
import AssignLearningPathDialog from "@/components/learning-paths/AssignLearningPathDialog";
import EventBus from "@/plugins/eventBus";
import GroupSelectInput from "@/components/layout/inputs/GroupSelectInput.vue";
import {
  AdjustmentsIcon,
  FilterIcon,
  LockClosedIcon,
  LockOpenIcon,
} from "@heroicons/vue/solid";

const sortBy = ref(null);
const filterBy = ref(null);
const filterByPrivacy = ref(null);

const sorts = ref([
  {
    name: "A-Z",
    value: "a-z",
  },
  {
    name: "Z-A",
    value: "z-a",
  },
]);

const privacies = ref([
  {
    name: "Private",
    value: "private",
  },
  {
    name: "Public",
    value: "public",
  },
]);

const filters = ref([
  {
    name: "Assigned",
    value: "assigned",
  },
  {
    name: "Not Started",
    value: "not-started",
  },
  {
    name: "In Progress",
    value: "in-progress",
  },
  {
    name: "Completed",
    value: "completed",
  },
]);
defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

watch(sortBy, function (value) {
  EventBus.$emit("UPDATE_LEARNING_PATH_DASHBOARD_SORT_BY", value);
});

watch(filterBy, function (value) {
  EventBus.$emit("UPDATE_LEARNING_PATH_DASHBOARD_FILTER_BY", value);
});

watch(filterByPrivacy, function (value) {
  EventBus.$emit("UPDATE_LEARNING_PATH_DASHBOARD_PRIVACY_BY", value);
});
</script>
