import { timezoneEndpoints } from "@/endpoints/timezone";
import { http } from "../httpService";
import { ref } from "vue";
import { useHandleErrorStatus } from "../useHandleErrorStatus";

export const getTimezones = () => {
  const loading = ref(true);
  const timezones = ref([]);
  const load = async () => {
    await http()
      .get(timezoneEndpoints.get, {
        params: {
          timezones: [
            "America/Chicago",
            "America/Denver",
            "America/Phoenix",
            "America/Los_Angeles",
            "America/Anchorage",
            "Pacific/Honolulu",
            "America/New_York",
          ],
        },
      })
      .then((response) => {
        timezones.value = response.data;
      })
      .catch((error) => {
        useHandleErrorStatus(error);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  return {
    load,
    loading,
    timezones,
  };
};
