import { storeToRefs } from "pinia";
import { useDisabledDirectiveStore } from "./useDisabledDirectiveStore";

const addClassToDisabledDiv = (classes) => {
  const disabledDirectiveStore = useDisabledDirectiveStore();
  const { classList } = storeToRefs(disabledDirectiveStore);
  if (Array.isArray(classes)) {
    classList.value = [...classList.value, ...classes];
    return;
  }

  if (typeof classes === "string") {
    const classToArray = classes.split(" ");
    classList.value = [...classList.value, ...classToArray];
    return;
  }
};

const addClassToContentText = (classes) => {
  const disabledDirectiveStore = useDisabledDirectiveStore();
  const { contentTextClassList } = storeToRefs(disabledDirectiveStore);
  if (Array.isArray(classes)) {
    contentTextClassList.value = [...contentTextClassList.value, ...classes];
    return;
  }

  if (typeof classes === "string") {
    const classToArray = classes.split(" ");
    contentTextClassList.value = [
      ...contentTextClassList.value,
      ...classToArray,
    ];
    return;
  }
};

const createElement = (el, binding) => {
  const divElement = document.createElement("div");
  divElement.setAttribute("id", "revdojo-disabled-div-directive");
  const contentElement = document.createElement("p");
  contentElement.setAttribute("id", "revdojo-disabled-div-content");
  const disabledDirectiveStore = useDisabledDirectiveStore();
  const { disabled, classList, contentTextClassList, contentText } =
    storeToRefs(disabledDirectiveStore);
  disabled.value = binding.value.disabled ? true : false;

  addClassToDisabledDiv(binding.value.class);
  addClassToContentText(binding.value.contentClass);

  contentText.value = binding.value.contentText ?? "Hide";
  contentElement.innerText = contentText.value;

  if (!divElement) {
    return;
  }

  classList.value.forEach((className) => {
    divElement.classList.add(className);
  });

  divElement.appendChild(contentElement);

  contentTextClassList.value.forEach((className) => {
    contentElement.classList.add(className);
  });

  el.appendChild(divElement);

  if (!disabled.value) {
    el.removeChild(divElement);
  }
};

/**
 * @params
 * binding = {
 *  disabled: boolean
 *  contentText: string
 *  class: main element classes | array or string
 *  contentClass: content classes | array or string
 * }
 */
export default {
  install: (app) => {
    app.directive("disabled", {
      created: (el) => {
        el.classList.add("relative");
      },
      mounted: (el, binding) => {
        createElement(el, binding);
      },
      updated: (el, binding) => {
        const disabledDirectiveStore = useDisabledDirectiveStore();
        const { disabled, contentTextClassList, classList, contentText } =
          storeToRefs(disabledDirectiveStore);
        disabled.value = binding.value.disabled ? true : false;
        contentText.value = binding.value.contentText ?? "Hide";
        const divElement = el.querySelector("#revdojo-disabled-div-directive");
        if (!divElement) {
          return;
        }

        const contentElement = divElement.querySelector(
          "#revdojo-disabled-div-content"
        );

        addClassToDisabledDiv(binding.value.class);
        addClassToContentText(binding.value.contentClass);

        contentElement.innerText = contentText.value;
        classList.value.forEach((className) => {
          divElement.classList.add(className);
        });
        contentTextClassList.value.forEach((className) => {
          contentElement.classList.add(className);
        });

        if (disabled.value) {
          return;
        }

        el.removeChild(divElement);
      },
      unmounted: (el) => {
        const childElement = el.querySelector(
          "#revdojo-disabled-div-directive"
        );
        if (childElement) {
          el.removeChild(childElement);
        }
      },
    });
  },
};
