<template>
  <div class="grid grid-cols-2 gap-4">
    <div>
      <span class="block text-sm font-medium text-untitled-gray-700"
        >Start Date</span
      >
      <el-date-picker
        v-bind="$attrs"
        v-model="date[0]"
        :type="type"
        :placeholder="placeholder"
        :format="format"
        :value-format="valueFormat"
        :readonly="readOnly"
        :disabled="disabled"
        :clearable="clearable"
        :disabled-date="disabledDates"
        :size="size"
        :start-placeholder="placeholder"
        :end-placeholder="placeholder"
        :range-separator="rangeSeparator"
        :shortcuts="shortcuts"
      />
      <span class="text-red-500 text-xs italic" v-show="props.error">
        Start Date is required.</span
      >
    </div>
    <div>
      <span class="block text-sm font-medium text-untitled-gray-700"
        >End Date</span
      >
      <el-date-picker
        v-bind="$attrs"
        v-model="date[1]"
        :type="type"
        :placeholder="placeholder"
        :format="format"
        :value-format="valueFormat"
        :readonly="readOnly"
        :disabled="disabled"
        :clearable="clearable"
        :disabled-date="disabledDates"
        :size="size"
        :start-placeholder="placeholder"
        :end-placeholder="placeholder"
        :range-separator="rangeSeparator"
        :shortcuts="shortcuts"
      />
      <span class="text-red-500 text-xs italic" v-show="props.error">
        End Date is required.</span
      >
    </div>
  </div>
</template>

<script setup>
import { ElDatePicker } from "element-plus";
import { defineProps, defineEmits, computed, watch } from "vue";
import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => {
      [null, null];
    },
  },
  type: {
    type: String,
    default: "datetime",
  },
  format: {
    type: String,
    default: "YYYY/MM/DD hh:mm:ss",
  },
  valueFormat: {
    type: String,
    default: "YYYY/MM/DD hh:mm:ss",
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  disabledBelowDates: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "large",
  },
  placeholder: {
    type: String,
    default: "Pick a date",
  },
  rangeSeparator: {
    type: String,
    default: "-",
  },
  shortcuts: {
    type: Array,
    default: () => [],
  },
  error: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const date = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit("update:modelValue", newValue);
  },
});

const type = computed(() => props.type);
const format = computed(() => props.format);
const valueFormat = computed(() => props.valueFormat);
const readOnly = computed(() => props.readOnly);
const disabled = computed(() => props.disabled);
const clearable = computed(() => props.clearable);
const disabledBelowDates = computed(() => props.disabledBelowDates);
const size = computed(() => props.size);
const placeholder = computed(() => props.placeholder);
const rangeSeparator = computed(() => props.rangeSeparator);
const shortcuts = computed(() => props.shortcuts);

const disabledDates = (time) => {
  if (disabledBelowDates.value) {
    const currentTime = dayjs();
    const targetTime = dayjs(time);

    return targetTime.isBefore(currentTime.subtract(1, "day"));
  }
  return false;
};

watch(
  () => date.value,
  ([startDate, endDate]) => {
    if (startDate && endDate && startDate > endDate) {
      date.value[1] = startDate;
    }
  },
  { deep: true }
);
</script>
