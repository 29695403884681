<template>
  <div class="py-10 lg:w-1/3 w-full md:pl-6 md:px-0 px-4">
    <div class="w-full">
      <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs font-normal uppercase px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer hover:bg-untitled-gray-200 hover:text-untitled-gray-800"
            v-on:click="toggleTabs(1)"
            v-bind:class="{
              'text-untitled-gray-600 bg-white': openTab !== 1,
              'text-untitled-gray-800 bg-untitled-gray-200': openTab === 1,
            }"
          >
            Report Summary
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs font-normal uppercase px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer hover:bg-untitled-gray-200 hover:text-untitled-gray-800"
            v-on:click="toggleTabs(2)"
            v-bind:class="{
              'text-untitled-gray-600 bg-white': openTab !== 2,
              'text-untitled-gray-800 bg-untitled-gray-200': openTab === 2,
            }"
          >
            My Videos
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs font-normal uppercase px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer hover:bg-untitled-gray-200 hover:text-untitled-gray-800"
            v-on:click="toggleTabs(3)"
            v-bind:class="{
              'text-untitled-gray-600 bg-white': openTab !== 3,
              'text-untitled-gray-800 bg-untitled-gray-200': openTab === 3,
            }"
          >
            {{ reminderEventTitle }}
          </a>
        </li>
      </ul>
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
      >
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }">
              <my-progress></my-progress>
              <div>
                <team-members/>
              </div>
            </div>
            <div v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }">
              <Suspense>
                <assigned-shared></assigned-shared>
                <template #fallback> Loading... </template>
              </Suspense>
            </div>
            <div v-if="openTab === 3">
              <reminder-section></reminder-section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="border-t border-untitled-gray-300 my-8" />
    <featured-video></featured-video>
    <hr class="border-t border-untitled-gray-300 my-8" />
    <GroupListSide></GroupListSide>
  </div>
</template>
<script setup>
import MyProgress from "./tabs/MyProgress.vue";
import AssignedShared from "./tabs/AssignedShared.vue";
import ReminderSection from "./tabs/ReminderSection.vue";
import FeaturedVideo from "./FeaturedVideo.vue";
import GroupListSide from "@/components/groups/GroupListSide.vue";
import TeamMembers from './TeamMembers.vue'
import { computed, ref } from "vue";
import { useAuthStore } from "@/stores";

const authUser = useAuthStore();
const openTab = ref(1);
const isSingleUser = computed(() => authUser.isSingleUser);

const toggleTabs = (tabNumber) => {
  openTab.value = tabNumber;
};

const reminderEventTitle = computed(() => {
  return isSingleUser.value ? "Reminders" : "Events & Reminders";
});
</script>
