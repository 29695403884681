<template>
  <div>
    <GroupFormModal v-model:openDialog="openGroupModal" />
    <GlobalDialog
      v-if="showAssignDialog"
      :titleCenter="false"
      dialogDefaultSize="35%"
      v-model="showAssignDialog"
      :isLoading="loadingAssign"
      :hasInput="hasInput"
      :title="title"
      submitLabel="Assign"
      @onSubmit="handleClickSubmit"
    >
      <template #content>
        <div
          class="px-4 w-full justify-start items-start text-left flex flex-col"
        >
          <div class="flex flex-col w-full">
            <div class="w-full">
              <EDateRangePicker
                v-model="dueDate"
                :error="showDateError"
                :disabled-below-dates="true"
                :teleported="true"
                :format="'MMM D, YYYY h:mm A'"
                :value-format="'YYYY-MM-DD h:mm A'"
                class="z-30"
                :use-time-now="true"
              />
            </div>
          </div>
          <div class="flex flex-col mt-2 w-full" v-if="showUsers">
            <label
              class="block text-sm font-medium text-untitled-gray-700 my-[8px]"
              >{{ usersLabel }}</label
            >
            <e-combobox
              :disabled="requireDates && !hasDueDate"
              v-model="form.users"
              name="users"
              :options="filteredUsers"
              :loading="loadingUsers"
              option-name="name"
              value-key="id"
              :placeholder="
                requireDates && !hasDueDate
                  ? 'Please set a due date first'
                  : 'Start typing to search for users....'
              "
              :multiple="true"
              :filterable="true"
              :clearable="true"
            />
            <ErrorSpan v-if="error && error.users">
              {{ error.users }}
            </ErrorSpan>
          </div>
          <div class="flex flex-col mt-2 w-full" v-if="showGroups">
            <div class="flex justify-between items-center mb-1">
              <label
                class="block text-sm font-medium text-untitled-gray-700 my-[8px]"
                >{{ groupsLabel }}</label
              >
              <a
                v-if="form.dueDate"
                class="cursor-pointer px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm true text-center h-[auto] w-[auto] justify-center inline-flex items-center text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
                v-has-restrict-learner
                @click="handleClickCreateGroup"
              >
                Create Group
              </a>
            </div>
            <e-combobox
              :disabled="requireDates && !hasDueDate"
              v-model="form.groups"
              name="groups"
              :options="groups"
              :loading="loadingGroups"
              option-name="name"
              value-key="id"
              :multiple="true"
              :filterable="true"
              :clearable="true"
              :placeholder="
                requireDates && !hasDueDate
                  ? 'Please set a due date first'
                  : 'Start typing to search groups....'
              "
            />
            <ErrorSpan v-if="error && error.groups">
              {{ error.groups }}
            </ErrorSpan>
          </div>
          <div
            class="flex flex-col mt-2 w-full"
            v-if="(isSuperAdmin || isAccountManager) && showDealers"
          >
            <label
              class="block text-sm font-medium text-untitled-gray-700 my-[8px]"
              >{{ dealersLabel }}</label
            >
            <ECombobox
              :disabled="requireDates && !hasDueDate"
              v-model="form.dealers"
              name="dealers"
              :options="dealers"
              :loading="loadingDealers"
              option-name="name"
              value-key="id"
              :multiple="true"
              :filterable="true"
              :clearable="true"
              :placeholder="
                requireDates && !hasDueDate
                  ? 'Please set a due date first'
                  : `Start typing to search ${labelsStore.labels.dealers}....`
              "
            />
            <ErrorSpan v-if="error && error.dealers">
              {{ error.dealers }}
            </ErrorSpan>
          </div>
          <div
            class="flex flex-col mt-2 w-full"
            v-if="
              (isSuperAdmin || isAccountManager) &&
              !isUnit &&
              !isModule &&
              !isPlaylist
            "
          >
            <label
              class="block text-sm font-medium text-untitled-gray-700 my-[8px]"
              >{{ positionLabel }}</label
            >
            <ECombobox
              v-model="form.positions"
              name="positions"
              :options="positions"
              :loading="loadingPositions"
              option-name="friendly_name"
              value-key="id"
              :multiple="true"
              :filterable="true"
              :clearable="true"
              :placeholder="
                requireDates && !hasDueDate
                  ? 'Please set a due date first'
                  : `Start typing to search positions....`
              "
              :disabled="requireDates && !hasDueDate"
            />
          </div>
        </div>
      </template>
    </GlobalDialog>
  </div>
</template>
<script setup>
import {
  computed,
  defineProps,
  defineEmits,
  ref,
  onMounted,
  onUnmounted,
  watch,
} from "vue";
import ECombobox from "../element-components/ECombobox.vue";
import GroupFormModal from "../groups/GroupFormModal.vue";
import GlobalDialog from "../layout/GlobalDialog.vue";
import ErrorSpan from "./ErrorSpan.vue";
import { useAssignToUsersStore } from "@/stores/useAssignToUsersStore";
import { useAuthStore } from "@/stores";
import { storeToRefs } from "pinia";
import { useFilteredUsers } from "@/composable/groups/useFilteredUsers";
import { http } from "@/composable/httpService";
import eventBus from "@/plugins/eventBus";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import formatDate from "@/composable/formatDate";
import { useLabelsStore } from "@/stores/useLabelsStore";
import EDateRangePicker from "@/components/element-components/EDateRangePicker.vue";
import { useLmsSettingsStore } from "@/stores/useLmsSettingsStore";
import { getPositions } from "@/composable/positions/getPositions";

const { positions, positionList, loadingPositions } = getPositions();

const lmsSettingStore = useLmsSettingsStore();
const requireDates = computed(
  () => lmsSettingStore.hasRequireAssignStartAndEndDate
);
const hasDueDate = computed(
  () => dueDate.value[0] != null && dueDate.value[1] != null
);
const showDateError = ref(false);
const dueDate = ref([null, null]);

const labelsStore = useLabelsStore();

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  courseId: {
    type: [Number, String],
  },
  title: {
    type: String,
    default: "Assign",
  },
  showCompanies: {
    type: Boolean,
    default: true,
    required: false,
  },
  showUsers: {
    type: Boolean,
    default: true,
    required: false,
  },
  showDealers: {
    type: Boolean,
    default: true,
    required: false,
  },
  showGroups: {
    type: Boolean,
    default: true,
    required: false,
  },
  dueDateLabel: {
    type: String,
    default: "Due Date",
  },
  usersLabel: {
    type: String,
    default: "Assign to users",
  },
  positionLabel: {
    type: String,
    default: "Auto Assign Course to Position",
  },
  groupsLabel: {
    type: String,
    default: "Assign to groups",
  },
  companiesLabel: {
    type: String,
    default: "Assign to companies",
  },
  isPlaylist: {
    type: Boolean,
    required: false,
  },
  playlistId: {
    type: [String, Number],
    required: false,
  },
  moduleId: {
    type: [String, Number],
    required: false,
  },
  isModule: {
    type: Boolean,
    required: false,
  },
  unitId: {
    type: [String, Number],
    required: false,
  },
  isUnit: {
    type: Boolean,
    required: false,
  },
});
const emit = defineEmits(["update:modelValue"]);
const authUser = useAuthStore();
const assignToUsersStore = useAssignToUsersStore();
const { users, loadingUsers, groups, loadingGroups, dealers, loadingDealers } =
  storeToRefs(assignToUsersStore);
const showAssignDialog = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit("update:modelValue", newValue);
  },
});

const loadingAssign = ref(false);
const isValidForm = ref(false);
const error = ref({});
const form = ref({
  groups: [],
  dueDate: null,
  users: [],
  dealers: [],
  positions: [],
});
const isPlaylist = computed(() => props.isPlaylist);
const playlistId = computed(() => props.playlistId);
const courseId = computed(() => props.courseId);
const title = computed(() => props.title);
const showUsers = computed(() => props.showUsers);
const showCompanies = computed(() => props.showCompanies);
const showDealers = computed(() => props.showDealers);
const showGroups = computed(() => props.showGroups);
const usersLabel = computed(() => props.usersLabel);
const groupsLabel = computed(() => props.groupsLabel);
const dealersLabel = computed(() => `Assign to ${labelsStore.labels.dealers}`);
const filteredUsers = computed(() =>
  useFilteredUsers(groups.value, form.value.groups, users.value)
);
const moduleId = computed(() => props.moduleId);
const isModule = computed(() => props.isModule);
const isUnit = computed(() => props.isUnit);
const unitId = computed(() => props.unitId);
const isSuperAdmin = computed(() => authUser.isSuperAdmin);
const isAccountManager = computed(() => authUser.isAccountManager);
const openGroupModal = ref(false);
const formattedDueDate = computed(() =>
  formatDate(form.value.dueDate, "YYYY-MM-DD HH:mm:ss")
);

const courseParams = computed(() => {
  return {
    course_id: courseId.value,
    users_id: form.value.users,
    groups_id: form.value.groups,
    dealers_id: form.value.dealers,
    start_date: dueDate.value[0],
    due_date: dueDate.value[1],
    positions: form.value.positions,
  };
});
const playlistParams = computed(() => {
  return {
    assigned_users: form.value.users,
    assigned_groups: form.value.groups,
    assigned_dealers: form.value.dealers,
    start_date: dueDate.value[0],
    due_date: dueDate.value[1],
    playlist_id: playlistId.value,
  };
});
const moduleParams = computed(() => {
  return {
    module_id: moduleId.value,
    user_ids: form.value.users,
    group_ids: form.value.groups,
    dealer_ids: form.value.dealers,
    notifyBy: [],
    dueDateFormatted: formattedDueDate.value,
    start_date: dueDate.value[0],
    due_date: dueDate.value[1],
  };
});
const unitParams = computed(() => {
  return {
    unit_id: props.unitId,
    user_ids: form.value.users,
    group_ids: form.value.groups,
    dealer_ids: form.value.dealers,
    notifyBy: [],
    start_date: dueDate.value[0],
    due_date: dueDate.value[1],
  };
});

const hasInput = computed(() => {
  return (
    form.value.users.length > 0 ||
    form.value.groups.length > 0 ||
    form.value.dealers.length > 0 ||
    dueDate.value[0] ||
    dueDate.value[1]
  );
});

const validateForm = () => {
  isValidForm.value = true;
  loadingAssign.value = false;
  const object = structuredClone(form.value);

  const checkIfHasValue = Object.keys(object)
    .map((key) => {
      return {
        [key]: object[key],
      };
    })
    .filter((obj) => {
      return Object.values(obj).some((value) => Array.isArray(value));
    })
    .some((obj) => {
      // Get the value (array) from the object
      const value = Object.values(obj)[0];
      // Check if the array has a length greater than zero
      return value.length > 0;
    });

  if (checkIfHasValue) {
    error.value = {};
    return;
  }

  isValidForm.value = false;
  Object.keys(object).forEach((key) => {
    if (Array.isArray(form.value[key]) && form.value[key].length === 0) {
      let label = key;
      if (key === "dealers") {
        label = labelsStore.labels.dealers;
      }
      error.value[key] = `This ${label} field is required.`;
    } else if (Array.isArray(form.value[key]) && form.value[key].length !== 0) {
      error.value[key] = "";
    }
  });
};
const handleClickSubmit = () => {
  checkDates();
  if (showDateError.value) {
    return;
  }

  loadingAssign.value = true;
  validateForm();
  if (isValidForm.value && isPlaylist.value && playlistId.value) {
    handleAssigningPlaylist();
    return;
  }
  if (isValidForm.value && moduleId.value && isModule.value) {
    handleAssigningModule();
    return;
  }
  if (isValidForm.value && unitId.value && isUnit.value) {
    handleAssigningUnit();
    return;
  }
  if (isValidForm.value && courseId.value) {
    handleAssigningCourse();
  }
};

const handleAssigningCourse = async () => {
  loadingAssign.value = true;
  try {
    await http().post("/v3/courses/assign", courseParams.value);
    eventBus.$emit("REFRESH_NOTIFICATION");
    toastNotification();
  } catch (error) {
    useHandleErrorStatus(error);
  } finally {
    loadingAssign.value = false;
    showAssignDialog.value = false;
  }
};

const handleAssigningPlaylist = async () => {
  loadingAssign.value = true;
  try {
    await http().post("/v3/learning-paths/assign", playlistParams.value);
    toastNotification();
    eventBus.$emit("REFRESH_PLAYLIST_ASSIGNEES");
  } catch (error) {
    useHandleErrorStatus(error);
  } finally {
    loadingAssign.value = false;
    showAssignDialog.value = false;
  }
};

const handleAssigningModule = async () => {
  loadingAssign.value = true;
  try {
    await http().post("/modules/assign", moduleParams.value);
    toastNotification();
  } catch (error) {
    useHandleErrorStatus(error);
  } finally {
    loadingAssign.value = false;
    showAssignDialog.value = false;
  }
};

const handleAssigningUnit = async () => {
  loadingAssign.value = true;
  try {
    await http().post("/units/assign", unitParams.value);
    toastNotification();
  } catch (error) {
    useHandleErrorStatus(error);
  } finally {
    loadingAssign.value = false;
    showAssignDialog.value = false;
  }
};

const checkDates = () => {
  if (
    !(dueDate.value[0] === null && dueDate.value[1] === null) &&
    !(dueDate.value[0] !== null && dueDate.value[1] !== null)
  ) {
    showDateError.value = true;
    return;
  }
  if (requireDates.value && !hasDueDate.value) {
    showDateError.value = true;
    return;
  }
  showDateError.value = false;
};

const toastNotification = () => {
  eventBus.$emit(
    "CREATE_NOTIFICATION",
    "Assigned successfully",
    "success",
    6500
  );
};

const handleFetchData = () => {
  handleResetForm();
  if (showUsers.value) {
    assignToUsersStore.handleFetchUsers();
  }

  if (showGroups.value) {
    assignToUsersStore.handleFetchGroups();
  }

  if (showCompanies.value) {
    assignToUsersStore.handleFetchCompanies();
  }

  if (showDealers.value) {
    assignToUsersStore.handleFetchDealers();
  }
};

const handleClickCreateGroup = () => {
  openGroupModal.value = !openGroupModal.value;
};

const handleResetForm = () => {
  form.value = {
    dueDate: null,
    users: [],
    groups: [],
    dealers: [],
  };

  dueDate.value = [null, null];
  showDateError.value = false;
  error.value = {};
};

onMounted(() => {
  eventBus.$on("addGroup", async (group) => {
    form.value.groups.push(group.id);
    if (!groups.value.map((group) => group.id).includes(group.id)) {
      groups.value.push(group);
    }
  });
});

onUnmounted(() => {
  handleResetForm();
});

watch(showAssignDialog, (value) => {
  if (!value) {
    return;
  }
  handleFetchData();
  positionList();
});
</script>
<style lang="scss" scoped></style>
