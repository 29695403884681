<template>
  <div class="flex flex-row gap-3">
    <div
      @click="handleClickTab('report_summary')"
      :class="[
        'p-2  rounded shadow flex-1 flex justify-center items-center cursor-pointer hover:bg-untitled-gray-50',
        {
          'bg-white': selectedRightSectionTab !== 'report_summary',
          'bg-untitled-gray-200 ': selectedRightSectionTab === 'report_summary',
        },
      ]"
    >
      <p
        class="text-xs text-untitled-gray-600 font-medium uppercase text-center"
      >
        Report Summary
      </p>
    </div>
    <div
      @click="handleClickTab('my_videos')"
      :class="[
        'p-2  rounded shadow flex-1 flex justify-center items-center cursor-pointer hover:bg-untitled-gray-50',
        {
          'bg-white': selectedRightSectionTab !== 'my_videos',
          'bg-untitled-gray-200 ': selectedRightSectionTab === 'my_videos',
        },
      ]"
    >
      <p
        class="text-xs text-untitled-gray-600 font-medium uppercase text-center"
      >
        My Videos
      </p>
    </div>
    <div
      @click="handleClickTab('events_and_reminders')"
      :class="[
        'p-2  rounded shadow flex-1 flex justify-center items-center cursor-pointer hover:bg-untitled-gray-50',
        {
          'bg-white': selectedRightSectionTab !== 'events_and_reminders',
          'bg-untitled-gray-200 ':
            selectedRightSectionTab === 'events_and_reminders',
        },
      ]"
    >
      <p
        class="text-xs text-untitled-gray-600 font-medium uppercase text-center"
      >
        Events & Reminders
      </p>
    </div>
  </div>
</template>

<script setup>
import { useHomeTabsStore } from "../../../stores/home/useHomeTabs.store";
import { storeToRefs } from "pinia";

const homeTabStore = useHomeTabsStore();
const { selectedRightSectionTab } = storeToRefs(homeTabStore);

const handleClickTab = (tab) => {
  selectedRightSectionTab.value = tab;
};
</script>

<style lang="scss" scoped></style>
