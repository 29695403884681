import axios from "axios";

export const isVimeoAvailable = async (id) => {
  try {
    const data = await axios.get(
      `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${id}`
    );

    return data?.status == 200;
  } catch {
    return false;
  }
};

export const isYoutubeAvailable = async (videoId) => {
  try {
    const response = await axios.get(`/api/is-youtube-available/${videoId}`);

    const status = response.data.items[0].status;

    if (status.privacyStatus == "private") {
      return false;
    }

    if (!status.embeddable) {
      return false;
    }
  } catch {
    return false;
  }

  return true;
};

export const useNumberWithCommas = (number, fractionDigit = 2) => {
  // Use toLocaleString with options to add commas
  if (!number) return 0;
  return number.toLocaleString("en-US", {
    maximumFractionDigits: fractionDigit,
  });
};
