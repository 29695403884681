import { ref, inject  } from 'vue';

export default function useScorm() {
    const axios = inject('axios');

    const isScormAvailable = ref(false);

    function checkScorm() {
        axios.get('/api/v3/scorm/health-check')
            .then(({ data }) => {
                if (data.status === 'ok') {
                    isScormAvailable.value = true;
                } else {
                    isScormAvailable.value = false;
                }
            })
            .catch(() => {
                isScormAvailable.value = false;
            });
    }

    return {
        isScormAvailable, 
        checkScorm,
    };
}
