<template>
  <el-space direction="vertical" alignment="flex-start">
    <ul role="list" class="my-1 divide-y divide-untitled-gray-200">
      <li v-for="i in 5" :key="i" class="py-1">
        <el-skeleton animated class="flex items-start my-1" :loading="loading">
          <template #template>
            <el-skeleton-item variant="image" class="image mr-5" />
            <div class="w-full mt-3">
              <el-skeleton-item variant="h4" class="h4" />
              <el-skeleton-item variant="text" class="text" />
            </div>
          </template>
        </el-skeleton>
      </li>
    </ul>
  </el-space>
</template>

<script setup>
import { ElSkeleton, ElSkeletonItem, ElSpace } from "element-plus";
import { computed, defineProps } from "vue";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const loading = computed(() => props.loading);
</script>

<style scoped>
.image {
  width: 225px !important;
  height: 73px !important;
}

.h4 {
  width: 40% !important;
}

.text {
  width: 70% !important;
}
</style>
