<script setup>
import { ref, defineProps, computed } from "vue";
import AssignDialogGlobal from "./AssignDialogGlobal.vue";
import { useDialogStore } from "@/stores/useDialogStore";
const dialogStore = useDialogStore();

const props = defineProps({
  title: {
    type: String,
    default: "Assign",
  },
  label: {
    type: String,
    default: "Assign",
  },
  courseId: {
    type: [String, Number],
    default: null,
  },
  playlistId: {
    type: [Number, String],
    required: false,
  },
  isPlaylist: {
    type: Boolean,
    required: false,
  },
  moduleId: {
    type: [String, Number],
    required: false,
  },
  isModule: {
    type: Boolean,
    required: false,
  },
  isUnit: {
    type: Boolean,
    required: false,
  },
  unitId: {
    type: [String, Number],
    required: false,
  },
});
const showAssignDialog = ref(false);
const loadingLabel = ref(false);
const title = computed(() => props.title);
const label = computed(() => props.label);
const courseId = computed(() => props.courseId);
const playlistId = computed(() => props.playlistId);
const isPlaylist = computed(() => props.isPlaylist);
const isModule = computed(() => props.isModule);
const moduleId = computed(() => props.moduleId);
const isUnit = computed(() => props.isUnit);
const unitId = computed(() => props.unitId);

const loadingLabelDisplay = computed(() =>
  loadingLabel.value ? "Loading..." : label.value
);

const handleClickAssignCourse = () => {
  showAssignDialog.value = true;
  dialogStore.showDialog();
};
</script>
<template>
  <slot
    :clickAssign="handleClickAssignCourse"
    :loadingLabel="loadingLabel"
    :loadingLabelDisplay="loadingLabelDisplay"
  >
    <button
      v-bind="$attrs"
      v-has-restrict-learner
      @click="handleClickAssignCourse"
      class="w-full inline-flex justify-center items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-900 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
    >
      {{ loadingLabelDisplay }}
    </button>
  </slot>
  <AssignDialogGlobal
    v-model="showAssignDialog"
    :courseId="courseId"
    :title="title"
    :playlistId="playlistId"
    :is-playlist="isPlaylist"
    :is-module="isModule"
    :module-id="moduleId"
    :unit-id="unitId"
    :is-unit="isUnit"
  />
</template>
<style lang="scss" scoped></style>
