<template>
  <ElButton
    @click="handleClickViewCourse"
    v-bind="$attrs"
    v-if="!hideButton"
    class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-600 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 mr-2"
  >
    Enroll Now
  </ElButton>
  <GlobalSlideOverPanel
    class="enroll-course-container"
    v-model="openCourse"
    @close-panel="handleCloseCourse"
    :title="
      courseProfile && courseProfile.name ? courseProfile.name : 'Loading...'
    "
    :new-drawer="true"
    size="medium"
  >
    <div class="w-full h-screen px-4" v-if="!loadingCourseProfile">
      <div class="pb-5 w-full flex justify-center items-center">
        <img
          v-if="courseProfile && courseProfile.unit_display"
          v-lazy="{
            src: courseProfile.unit_display.thumbnail,
            error:
              'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
            loading:
              'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...',
          }"
        />
      </div>
      <div class="enroll-course-container" v-if="showEnrollNowButton">
        <ElAffix target=".enroll-course-container" :offset="70">
          <div class="flex flex-col gap-2">
            <AssignToUser
              v-if="course.can_assign_contents && isManager"
              :course-id="course.id"
              v-slot="{ clickAssign }"
              :title="`Assign ${course.name} Course`"
              label="Assign Course"
              v-has-restrict-learner
            >
              <button
                @click="clickAssign"
                class="sticky top-8 text-lg shrink-0 xl:block bg-white rounded border w-full px-2 py-1 font-semibold text-gray-900 shadow-lg border-gray-400 hover:bg-gray-20 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
                v-has-restrict-learner
              >
                Assign Course
              </button>
            </AssignToUser>
            <button
              type="button"
              :disabled="loadingEnrollNow"
              @click="handleClickEnrollNow"
              class="sticky top-8 text-lg shrink-0 xl:block bg-white rounded border w-full px-2 py-1 font-semibold text-gray-900 shadow-lg border-gray-400 hover:bg-gray-20 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
            >
              {{
                loadingEnrollNow ? "Enrolling, Please wait..." : "Enroll Now"
              }}
            </button>
          </div>
        </ElAffix>
      </div>
      <div
        class="w-full py-4 mt-2 font-semibold text-md text-untitled-gray-700"
      >
        <p>Modules</p>
      </div>
      <div class="w-full" v-if="!loadingModules">
        <el-collapse v-model="activeNames">
          <el-collapse-item
            v-for="courseModule in modules"
            :key="courseModule.id"
            :title="courseModule.name"
            :name="courseModule.id"
          >
            <template #title>
              <div class="flex gap-x-2 w-full">
                <el-tooltip
                  :teleported="true"
                  :content="wrapTooltipContent(courseModule.name)"
                  effect="dark"
                  :raw-content="true"
                  placement="top-start"
                >
                  <span class="truncate font-semibold">{{
                    courseModule.name
                  }}</span>
                </el-tooltip>
              </div>
            </template>
            <div class="w-full">
              <UnitList :module-id="courseModule.id" />
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <GlobalListSkeleton v-if="loadingModules" :display="8" />
    </div>
  </GlobalSlideOverPanel>

  <AlertDialog
    :okButton="handleAlertOK"
    :cancelButton="handleAlertOK"
    :open="alertOpen"
    id="ViewCourse-Dialogbox"
    >{{ alertMessage }}</AlertDialog
  >
</template>
<script setup>
import { defineProps, computed, ref, inject, defineEmits, watch } from "vue";
import GlobalSlideOverPanel from "../layout/GlobalSlideOverPanel.vue";
import {
  ElCollapse,
  ElCollapseItem,
  ElTooltip,
  ElAffix,
  ElButton,
} from "element-plus";
import { http } from "@/composable/httpService";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { useEnrolledCourseStore } from "@/stores/enrolledCourseStore";
import { wrapTooltipContent } from "@/composable/wrapTooltipContent";
import UnitList from "./UnitList.vue";
import GlobalListSkeleton from "../layout/GlobalListSkeleton.vue";
import eventBus from "@/plugins/eventBus";
import { notificationMessage } from "@/helpers/error_message";
import { useAllAvailableCourseStore } from "@/stores/useAllAvailableCourse.store";
import AlertDialog from "@/components/dialogs/AlertDialog.vue";
import dayjs from "dayjs";
import AssignToUser from "@/components/utilities/AssignToUser.vue";
import { getRoles } from "@/composable/users/getRoles.js";
import { useDialogStore } from "@/stores/useDialogStore";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const props = defineProps({
  courseId: {
    type: [String, Number],
    default: null,
  },
  course: {
    type: Object,
    default: () => {},
  },
  showEnrollCourse: {
    type: Boolean,
    default: false,
    required: false,
  },
  hideButton: {
    type: Boolean,
    default: false,
  },
});

const dialogStore = useDialogStore();
const roles = getRoles();

const isSuperAdmin = roles?.isSuperAdmin?.value;
const isAccountManager = roles?.isAccountManager?.value;
const isStoreManager = roles?.isSpecificManager?.value;

const isManager = computed(() => {
  return isSuperAdmin || isAccountManager || isStoreManager;
});

const emit = defineEmits(["update:showEnrollCourse", "onEnrolledCourse"]);

const axios = inject("axios");
const enrolledCourseStore = useEnrolledCourseStore();
const allAvailableCourseStore = useAllAvailableCourseStore();
const showEnrollCourse = computed({
  get() {
    return props.showEnrollCourse;
  },
  set(value) {
    emit("update:showEnrollCourse", value);
  },
});
const viewedCourses = computed(() => enrolledCourseStore.viewedEnrolledCourse);
const courseId = computed(() => props.courseId);
const openCourse = ref(false);
const course = computed(() => props.course);
const loadingCourseProfile = ref(false);
const courseProfile = ref(null);
const modules = ref([]);
const loadingModules = ref(true);
const searchModule = ref("");
const showEnrollNowButton = ref(false);
const activeNames = ref([]);
const loadingEnrollNow = ref(false);
const hideButton = computed(() => props.hideButton);

const courseFirstUnitId = computed(() => {
  if (course.value && course.value.link) {
    return course.value.link.match(/\/units\/(\d+)/)[1];
  }

  return null;
});

const alertMessage = ref("");
const alertOpen = ref(false);

const handleClickViewCourse = () => {
  if (hasStartDate(course.value)) {
    alertMessage.value = `This course has been assigned to you by your manager and will only be available by ${dayjs(
      course.value.assigned_course.start_date
    ).format("MMMM D, YYYY hh:mm A")}`;
    alertOpen.value = true;
    return;
  }

  activeNames.value = [];
  openCourse.value = !openCourse.value;
  handleFetchCourseInformation();
  handleShowEnrollNowButton();
};

const setCourseProfileAndModules = () => {
  const viewCourse = viewedCourses.value.find(
    (course) => courseId.value === course.id
  );
  courseProfile.value = viewCourse;
  modules.value = viewCourse.modules ?? [];
  loadingModules.value = false;
  return;
};

const fetchCourseProfile = async () => {
  courseProfile.value = course.value && course.value.name ? course.value : null;
  if (courseProfile.value && courseProfile.value.name) {
    return;
  }
  loadingCourseProfile.value = true;
  await http()
    .get(`/client/lms/course/${courseId.value}`)
    .then((response) => {
      courseProfile.value = response.data;
    })
    .catch((error) => {
      useHandleErrorStatus(error);
    })
    .finally(() => (loadingCourseProfile.value = false));
};

const fetchCourseModules = async () => {
  loadingModules.value = true;
  await http()
    .get("fetchCourseModules", {
      params: {
        id: courseId.value,
        name: searchModule.value,
        sortBy: "default",
      },
    })
    .then((response) => {
      modules.value = response.data;
    })
    .catch((error) => {
      useHandleErrorStatus(error);
    })
    .finally(() => {
      loadingModules.value = false;
    });
};

const handleFetchCourseInformation = async () => {
  if (viewedCourses.value.map((course) => course.id).includes(courseId.value)) {
    setCourseProfileAndModules();
    return;
  }
  await fetchCourseProfile();
  await fetchCourseModules();
  courseProfile.value.modules = modules.value;
  enrolledCourseStore.setViewCourse(courseProfile.value);
};

const handleCloseCourse = () => {
  openCourse.value = false;
  showEnrollCourse.value = false;
  handleShowEnrollNowButton();
};
const handleClickEnrollNow = async () => {
  loadingEnrollNow.value = true;
  await axios
    .post(`api/v3/courses/${courseId.value}/enroll`)
    .then(() => {
      eventBus.$emit(
        "CREATE_NOTIFICATION",
        notificationMessage.startLearning(
          course.value.unit_display && course.value.unit_display.id
            ? course.value.unit_display.id
            : course.value.first_unit
            ? course.value.first_unit.id
            : courseFirstUnitId.value,
          course.value.name
        ),
        "success",
        8000
      );
      handleFilterCourseCategoryMegaMenu();
      emit("onEnrolledCourse");
    })
    .catch((error) => {
      console.log(error);
      // useHandleErrorStatus(error);
    })
    .finally(() => {
      loadingEnrollNow.value = false;
      handleCloseCourse();
    });
};

const handleFilterCourseCategoryMegaMenu = () => {
  allAvailableCourseStore.handleFilterCategoriesCourses(courseId.value);
};

const handleShowEnrollNowButton = () => {
  setTimeout(() => {
    showEnrollNowButton.value = !showEnrollNowButton.value;
  }, 200);
};

const hasStartDate = (course) => {
  return (
    course.assigned_course &&
    course.assigned_course.start_date &&
    dayjs(course.assigned_course.start_date).isAfter(dayjs())
  );
};

const handleAlertOK = () => {
  alertOpen.value = false;
};

watch(showEnrollCourse, (value) => {
  if (value) {
    handleClickViewCourse();
  }
});

watch(openCourse,(value) => {
  dialogStore.isDialogVisible = value
})
</script>
