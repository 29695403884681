<template>
  <div>
    <div class="flex items-center">
      <div>
        <p
          v-for="category in props.categories.slice(0, 5)"
          :key="category"
          class="inline-block"
        >
          <span
            @click="redirectToSearch(category)"
            class="mt-2 cursor-pointer inline-flex max-w-sm items-center rounded-full bg-untitled-gray-100 px-3 py-0.5 text-sm font-medium text-untitled-gray-800 mr-2"
          >
            <el-tooltip
              :content="wrapTooltipContent(category.name)"
              effect="dark"
              :raw-content="true"
              placement="top-start"
            >
              <p
                class="max-w-xs truncate font-bold text-xs text-untitled-gray-700"
              >
                {{ wrapCategoryName(category.name) }}
              </p>
            </el-tooltip>
          </span>
        </p>
        <el-popover
          :width="450"
          placement="bottom"
          trigger="hover"
          :visible="visible"
        >
          <template #reference v-if="props.categories.length >= 5">
            <span
              class="mt-2 cursor-pointer inline-flex max-w-sm items-center rounded-full bg-untitled-gray-100 px-3 py-0.5 text-sm font-medium text-untitled-gray-800 mr-2"
            >
              <p
                class="max-w-xs truncate font-bold text-xs text-untitled-gray-700"
              >
                {{ props.categories.length - 5 }}
                {{ props.categories.length - 5 > 1 ? "others" : "other" }}
              </p>
            </span>
            <FilterIcon class="w-4 text-untitled-gray-500" />
          </template>
          <template #default>
            <div class="flex items-center">
              <div class="justify-center">
                <p
                  v-for="category in props.categories.slice(5)"
                  :key="category.id"
                  class="inline-block"
                >
                  <span
                    @click="redirectToSearch(category)"
                    class="mt-2 cursor-pointer inline-flex max-w-sm items-center rounded-full bg-untitled-gray-100 px-3 py-0.5 text-sm font-medium text-untitled-gray-800 mr-2"
                  >
                    <el-tooltip
                      effect="dark"
                      :raw-content="true"
                      placement="bottom"
                      :content="wrapTooltipContent(category.name)"
                    >
                      <p
                        class="max-w-xs truncate font-bold text-xs text-untitled-gray-700"
                      >
                        {{ wrapCategoryName(category.name) }}
                      </p>
                    </el-tooltip>
                  </span>
                </p>
              </div>
            </div>
          </template>
        </el-popover>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { defineProps } from "vue";
import { ElTooltip } from "element-plus";
import { wrapTooltipContent } from "@/composable/wrapTooltipContent";
import { ElPopover } from "element-plus";

const props = defineProps({
  categories: {
    type: Object,
    required: true,
  },
});

const router = useRouter();

const redirectToSearch = (category) => {
  router.push({
    name: "search",
    query: {
      search: "",
      filter: "courses",
      category: category.id,
    },
  });
};

const wrapCategoryName = (name) => {
  return name.length > 15 ? name.slice(0, 15) + "..." : name;
};
</script>
