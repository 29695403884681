<template>
  <div>
    <div class="flex justify-center px-1 py-3">
      <button
        @click="openDialog"
        class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-untitled-gray-400 hover:text-untitled-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 ml-2"
      >
        See All Team Members
      </button>
    </div>

    <GlobalDialog
      v-model="dialog"
      dialogDefaultSize="40%"
      title="Team Members"
      :showActionButton="false"
    >
      <template #content>
        <div class="relative mt-2 p-2 px-5">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-7"
          >
            <SearchIcon
              class="inline h-4 w-4 text-untitled-gray-400"
              aria-hidden="false"
            />
          </div>
          <input
            id="Groups-Page-Search-field"
            name="search"
            class="block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm pl-10"
            placeholder="Type to search members."
            type="search"
            v-model="search"
            :disabled="loadingUsers"
          />
        </div>
        <div class="mt-5 min-h-">
          <div v-if="!records.length && !loadingUsers">
            <div class="flex justify-center items-center content-center">
              <h3 class="p-5">No members.</h3>
            </div>
          </div>
          <div v-if="loadingUsers" class="p-3">
            <el-skeleton :rows="7" animated  />
          </div>
          <ul
            role="list"
            class="my-1 p-3"
            @scroll="onScroll"
            style="height: 500px; overflow: auto"
            v-else
          >
            <li
              v-for="row in records"
              :key="row.id"
              class="p-5 mt-3 rounded shadow-md hover:shadow-lg shadow-untitled-gray-200 hover:shadow-untitled-gray-200"
            >
              <div class="flex items-center space-x-4">
                <div class="flex justify-between flex-1 min-w-0">
                  <p
                    class="text-sm font-medium text-untitled-gray-900 truncate"
                    :id="`Group-Members-List-Modal-list-${row.id}`"
                  >
                    {{ row.name }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </GlobalDialog>
  </div>
</template>

<script setup>
import { SearchIcon } from "@heroicons/vue/solid";
import { getUsers } from "@/composable/users/getUsers";
import { debounce as _debounce } from "lodash-es";
import { ref, watch } from "vue";
import GlobalDialog from "../layout/GlobalDialog.vue";
import { ElSkeleton } from 'element-plus'

const { userList, users, loadingUsers } = getUsers();

const search = ref("");
const dialog = ref(false);
const records = ref([])

const openDialog = async () => {
  dialog.value = true
  await userList()
  records.value = users.value
}

const searchUsers = _debounce(async (value) => {
   if(!value.length){
    records.value = users.value

    return
   }

   records.value = users.value.filter((user) => {
    return user.name.toLowerCase().includes(value.toLowerCase())
   })


}, 600);

watch(search, function (value) {
  searchUsers(value);
});
</script>
