export const getStatusColor = (status) => {
  if (status == "completed" || status == "Completed") {
    return "text-green-700";
  } else if (status == "overdue" || status == "Overdue") {
    return "text-red-700";
  } else if (status == "incomplete" || status == "In Progress") {
    return "text-yellow-700";
  } else {
    return "text-gray-700";
  }
};
