import dayjs from "dayjs";

export const postReminderPayload = (reminder, selectedUsers = null) => {
  let date = null;
  let scheduleTime = null;
  if (reminder.date && reminder.date != "No date") {
    scheduleTime = dayjs(reminder.date).format("HH:mm:ss")
    date = dayjs(reminder.date).format("YYYY-MM-DD")
  }

  return {
    ...reminder,
    name: reminder.name,
    description: reminder.description,
    users: selectedUsers,
    groups: [],
    endDate: date,
    customFields: [],
    timezone: reminder.timezone,
    message: reminder.message,
    schedule_date: date,
    schedule_time: scheduleTime,
    notifications: {
      email: true,
      sms: true,
      cadence: "one_time",
      schedule_date: date,
      cadence_time: scheduleTime,
      cadence_date: date,
      cadence_days: ["mon", "tue", "wed", "thu", "sun", "fri", "sat"],
    },
    editMode: true,
  };
};

export const updateReminderPayload = (reminder, selectedUsers = null) => {
  let date = null;
  let scheduleTime = null;
  if (reminder.assignedDateTime && reminder.assignedDateTime != "No date") {
    scheduleTime = dayjs(reminder.assignedDateTime).format("HH:mm:ss");
    date =
      dayjs(reminder.assignedDateTime).format("YYYY-MM-DD") +
      " " +
      scheduleTime;
  }

  return {
    id: reminder.id,
    name: reminder.name,
    description: reminder.description,
    users: selectedUsers,
    groups: [],
    endDate: reminder.end_date,
    customFields: [],
    timezone: reminder.timezone,
    message: reminder.message,
    schedule_date: date,
    schedule_time: scheduleTime,
    notifications: {
      email: true,
      sms: true,
      cadence: "one_time",
      schedule_date: date,
      cadence_time: scheduleTime,
      cadence_date: date,
      cadence_days: ["mon", "tue", "wed", "thu", "sun", "fri", "sat"],
    },
    editMode: true,
    selectedCompanies: reminder.selectedCompanies,
    selectedDealers: reminder.selectedDealers,
    removedDealerIds: reminder.removedDealerIds,
    removedCompanyIds: reminder.removedCompanyIds,
  };
};
