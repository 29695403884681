import { defineStore } from "pinia";
export const useUnitStore = defineStore({
  persist: true,
  id: "unitPage",
  state: () => ({
    unit: {},
    users: {},
    groups: {},
    nextUnit: {},
    nextUnitLoading: false,
    prevUnit: {},
    isFinishedVideo: false,
    isDoneAssessment: false,
    isSendArp: false,
  }),
  getters: {
    getUnit() {
      return this.unit.unit;
    },
    hasUnitQuiz() {
      if (this.getUnit.type === 'scorm') {
        return false;
      }
      return this.getUnit.quiz && this.getUnit.quiz.id && this.getUnit.quiz_id
        ? true
        : false;
    },
    isQuizDone() {
      return this.isDoneAssessment || this.getUnit.is_quiz_done;
    },
    updateCompanyUserViewCount(companyUserViewCount) {
      this.companyUserViewCount = companyUserViewCount;
    },
    isRequireArp() {
      if(!this.getUnit){
        return false
      }

      return this.getUnit.require_arp
    },
    canMarkAsComplete() {
      if (this.isRequireArp && this.hasUnitQuiz) {
        return this.isSendArp && this.isQuizDone && this.isFinishedVideo;
      }

      if (this.isRequireArp && !this.hasUnitQuiz) {
        return this.isSendArp && this.isFinishedVideo;
      }

      return true;
    }
  },
});
