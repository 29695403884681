<template>
  <div class="flex flex-col mt-2">
    <EDateRangePicker
      v-model="dueDate"
      :error="showDateError"
      :disabled-below-dates="true"
      :teleported="true"
      :format="'MMM D, YYYY h:mm A'"
      :value-format="'YYYY-MM-DD h:mm A'"
      class="z-30"
      :use-time-now="true"
    />
  </div>
  <div class="flex flex-col mt-2">
    <label class="block text-sm font-medium text-untitled-gray-700"
      >Assign to users</label
    >
    <e-combobox
      :disabled="requireDates && !hasDueDate"
      v-model="selectedUsers"
      name="users"
      :options="filteredUsers"
      :loading="fetchingLoading"
      option-name="name"
      value-key="id"
      :placeholder="
        requireDates && !hasDueDate
          ? 'Please set a due date first'
          : 'Start typing to search for users....'
      "
      :multiple="true"
      :filterable="true"
      :clearable="true"
    />
  </div>
  <div class="flex flex-col mt-2">
    <div class="flex justify-between items-center mb-1">
      <label class="block text-sm font-medium text-untitled-gray-700"
        >Assign to groups</label
      >
      <a
        v-if="!requireDates || hasDueDate"
        class="cursor-pointer px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm true text-center h-[auto] w-[auto] justify-center inline-flex items-center text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
        @click="handleClickCreateGroup"
      >
        Create Group
      </a>
    </div>
    <e-combobox
      :disabled="requireDates && !hasDueDate"
      v-model="selectedGroups"
      name="groups"
      :options="groups"
      :loading="fetchingLoading"
      option-name="name"
      value-key="id"
      :multiple="true"
      :filterable="true"
      :clearable="true"
      :placeholder="
        requireDates && !hasDueDate
          ? 'Please set a due date first'
          : 'Start typing to search groups....'
      "
    />
  </div>
  <div class="flex flex-col mt-2" v-if="isSuperAdmin || isAccountManager">
    <label class="block text-sm font-medium text-untitled-gray-700"
      >Assign to {{ labelsStore.labels.dealers }}</label
    >
    <e-combobox
      :disabled="requireDates && !hasDueDate"
      v-model="selectedDealers"
      name="dealers"
      :options="dealers"
      option-name="name"
      value-key="id"
      :multiple="true"
      :filterable="true"
      :clearable="true"
      :placeholder="
        requireDates && !hasDueDate
          ? 'Please set a due date first'
          : `Start typing to search ${labelsStore.labels.dealers}....`
      "
    />
  </div>

  <div
    v-if="
      (!requireDates || hasDueDate) &&
      selectedUsers.length === 0 &&
      selectedGroups.length === 0 &&
      selectedDealers.length === 0
    "
    class="mt-2"
  >
    <p class="text-sm text-red-600">{{ errorMsg }}</p>
  </div>

  <div class="flex mt-5 justify-end">
    <v-button
      :disabled="
        selectedUsers.length === 0 &&
        selectedGroups.length === 0 &&
        selectedDealers.length === 0
      "
      type="button"
      @click="handleSubmit()"
      class="justify-center inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
      :loading="loading"
      >Save</v-button
    >
    <v-button
      type="button"
      class="ml-2 inline-flex justify-center rounded-md border border-untitled-gray-300 bg-white px-4 py-2 text-base font-medium text-untitled-gray-700 shadow-sm hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
      @click="close()"
      ref="cancelButtonRef"
      :loading="loading"
      >Cancel</v-button
    >
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed } from "vue";
import { VButton } from "revdojo-vue-components";
import ECombobox from "@/components/element-components/ECombobox";
import EventBus from "@/plugins/eventBus";
import { useFilteredUsers } from "@/composable/groups/useFilteredUsers";
import { getRoles } from "@/composable/users/getRoles.js";
import EDateRangePicker from "@/components/element-components/EDateRangePicker.vue";
import { useLabelsStore } from "@/stores/useLabelsStore";
import { useLmsSettingsStore } from "@/stores/useLmsSettingsStore";

const labelsStore = useLabelsStore();
const roles = getRoles();
const isSuperAdmin = roles.isSuperAdmin.value;
const isAccountManager = roles.isAccountManager.value;

const lmsSettingStore = useLmsSettingsStore();
const requireDates = computed(
  () => lmsSettingStore.hasRequireAssignStartAndEndDate
);
const hasDueDate = computed(
  () => dueDate.value[0] != null && dueDate.value[1] != null
);
const showDateError = ref(false);

const props = defineProps({
  close: {
    type: Function,
    default: () => {},
  },
  users: {
    default: [],
  },
  groups: {
    default: [],
  },
  dealers: {
    default: [],
  },
});

const selectedUsers = ref([]);
const selectedGroups = ref([]);
const selectedDealers = ref([]);
const dueDate = ref([null, null]);
const errorMsg = ref("Atleast one user or group is required");
const emit = defineEmits(["createGroup", "assign"]);
const loading = ref(false);
const fetchingLoading = ref(false);
const setPayload = () => {
  return {
    assigned_users: selectedUsers.value,
    assigned_groups: selectedGroups.value,
    assigned_dealers: selectedDealers.value,
    start_date: dueDate.value[0],
    due_date: dueDate.value[1],
  };
};
const handleSubmit = () => {
  if (requireDates.value && !hasDueDate.value) {
    showDateError.value = true;
    return;
  }

  showDateError.value = false;

  emit("assign", setPayload());
};
const handleClickCreateGroup = () => {
  emit("createGroup");
};

EventBus.$on("addGroup", async (group) => {
  selectedGroups.value.push(group.id);
});

const propUsers = computed(() => props.users);
const propGroups = computed(() => props.groups);

const filteredUsers = computed(() =>
  useFilteredUsers(propGroups.value, selectedGroups.value, propUsers.value)
);
</script>
