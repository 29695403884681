import { http } from "@/composable/httpService";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { defineStore } from "pinia";
import { ref } from "vue";
import dayjs from "dayjs";
import { computed } from "vue";

export const useHomeEventsAndRemindersStore = defineStore(
  "homeEventsAndRemindersStore",
  () => {
    const events = ref([]);
    const loadingEvents = ref(false);
    const selectedTab = ref("events");
    const reminders = ref([]);
    const loadingReminders = ref(false);

    const eventsParams = computed(() => {
      return {
        userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isFromHomePage: true,
      };
    });

    const reminderParams = computed(() => {
      return {
        page: 1,
        limit: 5,
        fetchFromHome: true,
      };
    });

    const formatEventResponse = (event) => {
      return {
        ...event,
        fullDate: dayjs(event.start_at).format("MM/DD/YYYY hh:mm A"),
      };
    };

    const handleFetchEvents = async () => {
      try {
        loadingEvents.value = true;
        const { data } = await http().get("/event/list", {
          ...eventsParams.value,
        });
        events.value = data.map((item) => formatEventResponse(item));
      } catch (error) {
        useHandleErrorStatus(error);
      } finally {
        loadingEvents.value = false;
      }
    };

    const handleFetchReminders = async () => {
      try {
        loadingReminders.value = true;
        const { data } = await http().get("/goals", {
          params: reminderParams.value,
        });
        reminders.value = data.goals.data.map((goal) => {
          return formatReminderResponse(goal, reminders);
        });
      } catch (error) {
        useHandleErrorStatus(error);
      } finally {
        loadingReminders.value = false;
      }
    };

    const formatReminderResponse = (goal) => {
      const date = dayjs(goal.schedule_date).format("MM/DD/YYYY");
      const time = goal.schedule_time ? goal.schedule_time : "";
      const dateLong = `${date} ${time}`;

      goal.assignedDateTime = goal?.schedule_date
        ? dayjs(dateLong).format("MM/DD/YYYY hh:mm A")
        : "No date";
      goal.assignor = goal?.author?.name;
      goal.selectUsers = goal & goal.users ? goal.users : [];

      return goal;
    };

    return {
      events,
      handleFetchEvents,
      formatEventResponse,
      selectedTab,
      loadingEvents,
      handleFetchReminders,
      reminders,
      loadingReminders,
    };
  }
);
