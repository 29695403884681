import { defineStore } from "pinia";
import { ref } from "vue";

export const useDisabledDirectiveStore = defineStore(
  "disabledDirectiveStore",
  () => {
    const disabled = ref(false);
    const classList = ref([
      "absolute",
      "w-full",
      "h-full",
      "bg-gray-200",
      "inset-0",
      "bg-opacity-100",
      "flex",
      "justify-center",
      "items-center",
      "cursor-not-allowed",
    ]);
    const contentTextClassList = ref(["font-semibold", "text-lg"]);
    const contentText = ref("Hide");

    return {
      disabled,
      classList,
      contentTextClassList,
      contentText,
    };
  }
);
