import { ref, inject } from "vue";

export const getPositions = () => {
  const positions = ref([]);
  const loadingPositions = ref(false);
  const axios = inject("axios");

  const positionList = async (companyIds,storeIds) => {
    loadingPositions.value = true;
    const response = await axios.get("/api/fetchPositions", {
      params: { companyIds, storeIds },
    });
    positions.value = response.data;
    loadingPositions.value = false;
  };

  const fetchMappedPositions = () => {
    return positions.value.map(({ id, friendly_name, dealer }) => ({
      label: dealer?.name ? `${friendly_name} - ${dealer.name}` : friendly_name,
      value: id,
    }));
  };

  return { positions, positionList, loadingPositions, fetchMappedPositions };
};
