<template>
  <div class="animate-pulse">
    <div class="mt-8 flex items-stretch text-center">
      <div class="w-full md:w-1/2 bg-gray-100 py-8 px-4 mr-2 cursor-pointer">
        <h3 class="h-10 bg-gray-200 rounded-full dark:bg-gray-700 mb-3"></h3>
        <p class="text-sm text-center text-gray-600">Courses Completed</p>
      </div>
      <div class="w-full md:w-1/2 bg-gray-100 py-8 px-4 ml-2 cursor-pointer">
        <h3 class="h-10 bg-gray-200 rounded-full dark:bg-gray-700 mb-3"></h3>
        <p class="text-sm text-center text-gray-600">Certificates Earned</p>
      </div>
    </div>
    <div class="mt-4 flex items-stretch">
      <div class="w-full md:w-1/2 bg-gray-100 py-8 px-4 mr-2 cursor-pointer">
        <h3 class="h-10 bg-gray-200 rounded-full dark:bg-gray-700 mb-3"></h3>
        <p class="text-sm text-center text-gray-600">Courses in Progress</p>
      </div>
      <div class="w-full md:w-1/2 bg-gray-100 py-8 px-4 ml-2 cursor-pointer">
        <h3 class="h-10 bg-gray-200 rounded-full dark:bg-gray-700 mb-3"></h3>
        <p class="text-sm text-center text-gray-600">Assigned to You</p>
      </div>
    </div>
    <div v-if="props.hasViewTeamProgressPermission">
      <div class="flex flex-col items-stretch">
        <h5 class="mt-8 mb-2 text-gray-800">Your Team’s Progress</h5>
      </div>
      <hr class="mb-6 border-t border-untitled-gray-300" />
      <div class="mt-4 flex items-stretch">
        <div class="w-full md:w-1/2 bg-gray-100 py-8 px-4 mr-2 cursor-pointer">
          <h3 class="h-10 bg-gray-200 rounded-full dark:bg-gray-700 mb-3"></h3>
          <p class="text-sm text-center text-gray-600">
            Completed <br />
            Assignments
          </p>
        </div>
        <div class="w-full md:w-1/3 bg-gray-100 py-8 px-4 ml-2 cursor-pointer">
          <h3 class="h-10 bg-gray-200 rounded-full dark:bg-gray-700 mb-3"></h3>
          <p class="text-sm text-center text-gray-600">
            In Progress <br />
            Assignments
          </p>
        </div>
        <div class="w-full md:w-1/3 bg-gray-100 py-8 px-4 ml-2 cursor-pointer">
          <h3 class="h-10 bg-gray-200 rounded-full dark:bg-gray-700 mb-3"></h3>
          <p class="text-sm text-center text-gray-600">
            Past Due <br />
            Assignments
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  hasViewTeamProgressPermission: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped></style>
