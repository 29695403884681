<template>
  <div ref="deferredContainer" class="w-full">
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, computed, watch, defineProps } from "vue";
const props = defineProps({
  threshold: {
    type: Number,
    default: 0.5,
  },
  rootMargin: {
    type: String,
    default: "0px",
  },
  root: {
    type: Element,
    default: null,
  },
});

const emit = defineEmits(["onLoadComponent", "onVisible"]);
const deferredContainer = ref();
const wrapper = ref(null);
const isVisible = ref(false);
const threshold = computed(() => props.threshold);
const root = computed(() => props.root);
const rootMargin = computed(() => props.rootMargin);

const observer = new IntersectionObserver(
  (entries) => {
    if (
      entries[0].isIntersecting &&
      entries[0].intersectionRatio >= threshold.value
    ) {
      isVisible.value = true;
      observer.disconnect();
      emit("onLoadComponent");
    }
  },
  {
    root: root.value ?? wrapper.value,
    rootMargin: rootMargin.value,
    threshold: threshold.value,
  }
);

onMounted(() => {
  if (deferredContainer.value) {
    observer.observe(deferredContainer.value);
  }
});

watch(isVisible, (value) => {
  emit("onVisible", value);
});
</script>
