const LmsSettingsModule = () => import("./LmsSettings.vue");

const moduleRoute = {
  path: "/settings",
  component: LmsSettingsModule,
  props: true,
  children: [
    {
      path: "",
      name: "LMS Settings Page",
      component: () => import("./views/LmsSettingsPage.vue"),
      props: true,
      meta: {
        title: "LMS Settings",
        auth: true,
      },
    },
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};
