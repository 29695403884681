export function useFilteredUsers(groups, selectedGroups, users) {
  if (groups === undefined || users === undefined) {
    return users;
  }

  const selectedGroupUsers = groups
    .filter((group) => selectedGroups.includes(group.id))
    .flatMap((group) => group.users);

  if (selectedGroupUsers.length === 0) {
    return users;
  }

  return users.filter((user) => !selectedGroupUsers.includes(user.id));
}
