import * as Vue from "vue";
import App from "./App.vue";
import "./assets/css/index.css";
import { router } from "./router";
import "./modules";
import "jodit/build/jodit.min.css";
import VueAxios from "vue-axios";
import axios from "./plugins/axios";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import auth from "./plugins/auth";
import VueLazyLoad from "vue3-lazyload";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import "@vuepic/vue-datepicker/dist/main.css";
import "element-plus/dist/index.css";
import LmsSettingDirectives from "@/plugins/settings_directives";
import LayoutDirectives from "@/plugins/layout_directives/disabled_div_element_directive";

Bugsnag.start({
  autoDetectErrors: false,
  apiKey: "90a25bc1d28c918649fca19b3f83e290",
  plugins: [new BugsnagPluginVue()],
  ReleaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ["production", "staging"],
});

const bugsnagVue = Bugsnag.getPlugin("vue");

const app = Vue.createApp(App);

const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

app.use(bugsnagVue);
app.use(pinia);
app.use(router);
app.use(VueLazyLoad, {
  loading: "",
  error: "",
  lifecycle: {
    loading: (el) => {
      el.classList.add("is-loading");
      el.classList.remove("is-loaded");
    },
    error: (el) => {
      el.classList.add("is-error");
      el.classList.remove("is-loaded");
    },
    loaded: (el) => {
      el.classList.add("is-loaded");
      el.classList.remove("is-loading");
      el.classList.remove("is-error");
    },
  },
});
app.use(VueAxios, axios.createAxiosInstance());
app.provide("axios", app.config.globalProperties.axios);

app.router = router;
app.use(auth);
app.use(LmsSettingDirectives);
app.use(LayoutDirectives);
app.mount("#app");
