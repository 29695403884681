<script setup>
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { http } from "@/composable/httpService";
import { defineProps, computed, onMounted, ref } from "vue";
import GlobalListSkeleton from "../layout/GlobalListSkeleton.vue";
import GlobalEmpty from "../layout/GlobalEmpty.vue";
const props = defineProps({
  moduleId: {
    type: [Number, String],
    required: true,
  },
});

const moduleId = computed(() => props.moduleId);
const units = ref([]);
const loadingUnits = ref(false);
const handleFetchUnitsByModule = async () => {
  loadingUnits.value = true;
  await http()
    .get("fetchUnits", {
      params: {
        id: moduleId.value,
      },
    })
    .then((response) => {
      units.value = response.data;
    })
    .catch((error) => {
      useHandleErrorStatus(error);
    })
    .finally(() => (loadingUnits.value = false));
};

onMounted(handleFetchUnitsByModule);
</script>
<template>
  <div
    v-if="!loadingUnits"
    :class="[
      units.length === 0 ? '' : 'bg-untitled-gray-100',
      'w-full px-4 py-2',
    ]"
  >
    <div
      class="flex border boder-gray-300 my-2 bg-white"
      v-for="{ id, thumbnail, name } in units"
      :key="id"
    >
      <div
        class="w-32 flex justify-center items-center border-r border-gray-300"
      >
        <img
          class="max-w-[128px]"
          v-lazy="{
            src: thumbnail,
            error:
              'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
            loading:
              'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...',
          }"
        />
      </div>
      <div class="line-clamp-2 flex justify-start items-center text-sm pl-2">
        {{ name }}
      </div>
    </div>
  </div>
  <GlobalListSkeleton :display="6" v-if="loadingUnits" />
  <GlobalEmpty
    v-if="!loadingUnits && units.length === 0"
    title="No units available"
    height="h-32"
  />
</template>
