import { useAuthStore } from "@/stores";
import axios from "axios";

export function http() {
  const authStore = useAuthStore();
  return axios.create({
    baseURL: "/api",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${authStore.$state.token}`,
    },
  });
}
