import { defineStore } from "pinia";
import { findIndex as _findIndex } from "lodash-es";

export const useLearningPathStore = defineStore("learningPath", {
  state: () => ({
    learningPaths: [],
  }),
  actions: {
    addLearningPath(learningPath) {
      const learningPathIndex = _findIndex(this.learningPaths, {
        learning_path_id: learningPath.learning_path_id,
      });

      if (learningPathIndex !== -1) {
        return;
      }

      this.learningPaths.push(learningPath);
    },
    removeLearningPath(learningPathId) {
      const learningPathIndex = _findIndex(this.learningPaths, {
        learning_path_id: learningPathId,
      });

      if (learningPathIndex !== -1) {
        this.learningPaths.splice(learningPathIndex, 1);
      }
    },
  },
});
