<template>
  <GlobalDialog
    v-if="open"
    v-model="open"
    dialogDefaultSize="35%"
    :title="group.id ? 'Edit Group' : 'Create Group'"
    @onSubmit="handleClickSave"
    :is-loading="loading"
    submit-label="Save"
  >
    <template #content>
      <div class="px-4">
        <SkeletonGroupUsers :lineCount="2" v-if="loading" />
        <div class="mt-5 min-h-" v-else>
          <div class="mb-5">
            <input
              type="text"
              placeholder="Group name"
              v-model="group.name"
              maxlength="50"
              required
              id="Group-Form-Modal-Name-field"
              class="mr-3block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm"
            />
            <ErrorSpan v-if="errorData && errorData.name">
              {{ errorData.name[0] }}</ErrorSpan
            >
          </div>
          <div class="mt-1">
            <e-combobox
              v-model="selectedUserIds"
              :options="userDropdownOptions"
              option-name="label"
              value-key="value"
              placeholder="Add users as members to your group"
              :multiple="true"
              :filterable="true"
              :clearable="true"
              :reserve-keyword="false"
              id="Group-Form-Modal-Members-field"
            />
            <ErrorSpan v-if="errorData && errorData.users">
              {{ errorData.users[0] }}</ErrorSpan
            >
          </div>
        </div>
      </div>
    </template>
  </GlobalDialog>
</template>

<script setup>
import {
  ref,
  defineEmits,
  inject,
  defineProps,
  onBeforeUnmount,
  watch,
  computed,
} from "vue";
import { getUsers } from "@/composable/users/getUsers";
import ECombobox from "@/components/element-components/ECombobox";
import Bugsnag from "@bugsnag/js";
import EventBus from "@/plugins/eventBus";
import SkeletonGroupUsers from "@/components/loaders/SkeletonGroupUsers.vue";
import ErrorSpan from "../utilities/ErrorSpan.vue";
import GlobalDialog from "../layout/GlobalDialog.vue";

const props = defineProps({
  eventId: {
    type: String,
    default: "",
  },
  openDialog: {
    type: Boolean,
  },
});

const axios = inject("axios");
const userDropdownOptions = ref([]);
const selectedUserIds = ref([]);
const open = ref(false);
const loading = ref(false);
const selectedGroupId = ref(null);

const group = ref({
  id: null,
  name: "",
});

const errorData = ref(null);

const { userList, users } = getUsers();

const emit = defineEmits(["save", "update:openDialog"]);

const openDialog = computed({
  get() {
    return props.openDialog;
  },
  set(newValue) {
    emit("update:openDialog", newValue);
  },
});

const handleClickSave = async () => {
  loading.value = true;
  errorData.value = null;

  const formData = {
    name: group.value.name,
    users: selectedUserIds.value,
  };

  if (!group.value.id) {
    await createGroup(formData);
    return;
  }

  formData.id = group.value.id;
  await updateGroup(group.value.id, formData);
};

const createGroup = async (formData) => {
  await axios
    .post("/api/v3/groups", formData)
    .then((response) => {
      EventBus.$emit("addGroup", response.data);

      emit("save", "Group created successfully");
      open.value = false;
    })
    .catch((error) => {
      handleError(error);
      Bugsnag.notify(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const updateGroup = async (id, formData) => {
  await axios
    .put(`/api/v3/groups/${id}`, formData)
    .then((response) => {
      EventBus.$emit("updateGroup", response.data);

      emit("save", "Group updated successfully");
      open.value = false;
    })
    .catch((error) => {
      handleError(error);
      Bugsnag.notify(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const handleError = (error) => {
  if (error.response.status == 422) {
    errorData.value = error.response.data;
  }
};

const initializeUsers = async () => {
  await userList({
    group_id: selectedGroupId.value ? selectedGroupId.value : null,
  });

  userDropdownOptions.value = users?.value.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
};

EventBus.$on(
  `openGroupFormModal${props.eventId}`,
  async (selectedGroup = null) => {
    errorData.value = null;
    selectedUserIds.value = [];
    group.value = {
      id: null,
      name: "",
    };

    loading.value = true;
    open.value = true;

    if (!selectedGroup) {
      loading.value = false;
      return;
    }

    selectedGroupId.value = selectedGroup.id;

    await axios
      .get(`/api/v3/groups/${selectedGroup.id}`)
      .then((response) => {
        group.value = response.data;
        selectedUserIds.value = group.value.users;
      })
      .catch((error) => {
        Bugsnag.notify(error);
      })
      .finally(() => {
        loading.value = false;
      });
  }
);

onBeforeUnmount(() => {
  EventBus.$off(`openGroupFormModal${props.eventId}`);
});

watch(open, async () => {
  if (open.value) {
    errorData.value = null;
    await initializeUsers();
  } else {
    openDialog.value = false;
  }
});

watch(openDialog, (value) => {
  open.value = value;
});
</script>
