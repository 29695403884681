<template>
  <div
    class="flex flex-col sm:flex-wrap md:flex-row gap-3 bg-opacity-90 bg-untitled-gray-50"
  >
    <div
      class="flex justify-center items-center bg-white p-2 border border-untitled-gray-200 md:shadow-lg rounded-md cursor-pointer drop-shadow-sm gap-2"
      @click="handleClickTab('featured')"
      v-if="selectedMainTab !== 'featured'"
    >
      <el-tooltip
        :content="wrapTooltipContent('Show All Featured Contents')"
        effect="dark"
        :raw-content="true"
        placement="bottom"
      >
        <HomeIcon class="w-7 text-untitled-gray-600 hidden md:block" />
      </el-tooltip>
      <p
        class="text-xs text-untitled-gray-600 font-semibold uppercase text-center md:hidden"
      >
        Featured Content
      </p>
    </div>
    <div
      class="p-2 sm:p-3 rounded-md shadow flex-1 flex justify-center items-center cursor-pointer hover:bg-untitled-gray-100"
      @click="handleClickTab('enrolled')"
      :class="[
        {
          'bg-untitled-gray-200': selectedMainTab === 'enrolled',
          'bg-white': selectedMainTab !== 'enrolled',
        },
      ]"
    >
      <p
        class="text-xs text-untitled-gray-600 font-semibold uppercase text-center"
      >
        {{ tabName }}
      </p>
    </div>
    <div
      class="p-2 sm:p-3 rounded-md shadow flex-1 flex justify-center items-center cursor-pointer hover:bg-untitled-gray-100"
      @click="handleClickTab('playlist')"
      :class="[
        {
          'bg-untitled-gray-200': selectedMainTab === 'playlist',
          'bg-white': selectedMainTab !== 'playlist',
        },
      ]"
    >
      <p
        class="text-xs text-untitled-gray-600 font-semibold uppercase text-center"
      >
        {{ userName }} Learning Path & Playlists
      </p>
    </div>
    <div
      class="p-2 sm:p-3 rounded-md shadow flex-1 flex justify-center items-center cursor-pointer hover:bg-untitled-gray-100"
      @click="handleClickTab('content')"
      :class="[
        {
          'bg-untitled-gray-200': selectedMainTab === 'content',
          'bg-white': selectedMainTab !== 'content',
        },
      ]"
    >
      <p
        class="text-xs text-untitled-gray-600 font-semibold uppercase text-center"
      >
        All Available Content
      </p>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores";
import { useHomeCoursesStore } from "@/stores/home/useHomeCourses.store";
import { useHomeTabsStore } from "@/stores/home/useHomeTabs.store";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { HomeIcon } from "@heroicons/vue/solid";
import { ElTooltip } from "element-plus";
import { wrapTooltipContent } from "@/composable/wrapTooltipContent";

const authStore = useAuthStore();
const homeCoursesStore = useHomeCoursesStore();
const homeTabStore = useHomeTabsStore();

const { selectedMainTab } = storeToRefs(homeTabStore);

const tabName = computed(() => homeCoursesStore.homeTab);
const userName = computed(() => authStore.fullName);

const handleClickTab = (tabName) => {
  selectedMainTab.value = tabName;
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
</script>

<style lang="scss" scoped></style>
