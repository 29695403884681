<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-10">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
          leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel
                class="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div class="mt-3 text-center sm:text-left">
                  <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Group Members
                  </DialogTitle>

                  <div class="relative mt-2">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <SearchIcon class="inline h-4 w-4 text-untitled-gray-400" aria-hidden="false" />
                    </div>
                    <input id="Groups-Page-Search-field" name="search"
                      class="block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm pl-10"
                      placeholder="Type to search members on group." type="search" v-model="search" :disabled="loading" />
                  </div>

                  <SkeletonGroupUsers v-if="loading && !groupUsers" />
                  <div class="mt-5 min-h-" v-else>
                    <div v-if="!groupUsers?.length">
                      <div class="flex justify-center items-center content-center">
                        <h3 class="p-5">No members.</h3>
                      </div>
                    </div>

                    <ul role="list" class="my-1" @scroll="onScroll" style="height: 500px; overflow: auto" v-else>
                      <li v-for="row in groupUsers" :key="row"
                        class="p-5 mt-3 rounded shadow-md hover:shadow-lg shadow-untitled-gray-200 hover:shadow-untitled-gray-200">
                        <div class="flex items-center space-x-4">
                          <div class="flex justify-between flex-1 min-w-0">
                            <p class="text-sm font-medium text-untitled-gray-900 truncate"
                              :id="`Group-Members-List-Modal-list-${row.user.id}`">
                              {{ row.user.name }} {{ isOwner }}
                            </p>
                            <div class="flex flex-col items-center w-[120px]">
                              <b class="text-xs text-untitled-gray-500">{{
                                isGroupOwner(row.user.id)
                                ? "Owner"
                                : groupRole(row.is_admin)
                              }}</b>
                              <button v-if="isAdmin && !isGroupOwner(row.user.id)"
                                class="hover:cursor-pointer text-xs text-untitled-gray-100 bg-untitled-gray-400 rounded-md p-2 min-w-[120px] max-w-[120px]"
                                @click="showChangeRoleDialog(row)">
                                {{
                                  groupRole(row.is_admin) === "Admin"
                                  ? "Remove"
                                  : "Set"
                                }}
                                as Admin
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="pb-5 pt-5 text-center" v-if="loading && groupUsers">
                        <span>Loading . . . </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button type="button" id="Group-Members-List-Modal-Cancel-button"
                    class="inline-flex justify-center rounded-md border border-untitled-gray-300 bg-white px-4 py-2 text-base font-medium text-untitled-gray-700 shadow-sm hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="cancelButton()" :disabled="loading">
                    Close
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <ConfirmDialog :okButton="() => changeRole(selectedRow)" :cancelButton="() => (confirmShow = false)"
      :open="confirmShow" id="GroupUser-ConfirmDialog-Dialogbox">{{ confirmMessage }}</ConfirmDialog>
  </div>
</template>

<script setup>
import { SearchIcon } from "@heroicons/vue/solid";
import { ref, inject, defineProps,watch } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import Bugsnag from "@bugsnag/js";
import EventBus from "@/plugins/eventBus";
import SkeletonGroupUsers from "@/components/loaders/SkeletonGroupUsers.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
import { getRoles } from "@/composable/users/getRoles.js";
import { debounce as _debounce } from "lodash-es";

const roles = getRoles();
const isSuperAdmin = roles.isSuperAdmin.value;

const props = defineProps({
  eventId: {
    type: String,
    default: "",
  },
});

const axios = inject("axios");
const open = ref(false);
const loading = ref(false);
const isAdmin = ref(false);
const ownerId = ref(-1);
const confirmShow = ref(false);
const confirmMessage = ref("");
const selectedRow = ref(null);

const group = ref({
  id: null,
  name: "",
});

const groupUsers = ref(null);

const cancelButton = () => {
  open.value = false;
};

const page = ref(1);
const total = ref(0);
const lastPage = ref(1);
const search = ref("");
const sort = ref("asc");

EventBus.$on(
  `openGroupMemberListModal${props.eventId}`,
  async (selectedGroup = null) => {
    groupUsers.value = null;
    group.value = selectedGroup;
    open.value = true;
    page.value = 1;

    getGroupUsers(selectedGroup);
  }
);

const showChangeRoleDialog = (value) => {
  selectedRow.value = value;
  confirmMessage.value = `Are you sure you want to ${value.is_admin ? "remove" : "make"
    } ${value.user.name} as an admin?`;
  confirmShow.value = true;
};
const groupRole = (value) => {
  return value ? "Admin" : "Member";
};

const changeRole = async (value) => {
  const payload = {
    group_id: value.group_id,
    user_id: value.user_id,
    is_admin: value.is_admin ? false : true,
  };

  confirmShow.value = false;

  await axios
    .post("/api/v3/groups/change-role", payload)
    .then(() => {
      selectedRow.value.is_admin = !selectedRow.value.is_admin;
      EventBus.$emit(
        "CREATE_NOTIFICATION",
        "Successfully change group role",
        "success",
        6500
      );
    })
    .catch((error) => {
      EventBus.$emit(
        "CREATE_NOTIFICATION",
        "Failed to change group role",
        "warning",
        6500
      );
      Bugsnag.notify(error);
    });
};

const isGroupOwner = (userId) => {
  return userId == ownerId.value;
};

const getGroupUsers = async () => {
  loading.value = true;

  await axios
    .get(`/api/v3/groups/${group.value.id}/users`, {
      params: {
        page: page.value,
        search: search.value,
        sort: sort.value,
      },
    })
    .then((response) => {
      lastPage.value = response.data.last_page;
      total.value = response.data.total;
      isAdmin.value =response.data.data[0].is_group_admin || isSuperAdmin;
      ownerId.value = response.data.data[0].owner_id;

      if (page.value == 1) {
        groupUsers.value = response.data?.data;
      } else {
        groupUsers.value.push(...response.data?.data);
      }
    })
    .catch((error) => {
      Bugsnag.notify(error);
    })
    .finally(() => {
      loading.value = false;
    });

  open.value = true;
};

const fetchGroups = (value, type) => {
  if (type === "scroll") {
    if (page.value <= lastPage.value) {
      page.value += 1;
      getGroupUsers(value);
    }
  }

  if (type === "sort") {
    page.value = 1;
    getGroupUsers(value);
  }
};

const onScroll = ({ target: { scrollTop, clientHeight, scrollHeight } }) => {
  if (scrollTop + clientHeight >= scrollHeight) {
    fetchGroups(sort.value, "scroll");
  }
};

watch(search, _debounce(function(){
  getGroupUsers()
},1000))
</script>
