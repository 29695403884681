<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-[10000000000]">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div v-if="!isCustom">
                <div class="sm:flex sm:items-start">
                  <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <ExclamationIcon
                      class="h-6 w-6 text-gray-600"
                      aria-hidden="true"
                    />
                  </div>

                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle
                      as="h3"
                      class="text-lg font-medium leading-6 text-gray-900"
                      >{{ dialogTitle }}</DialogTitle
                    >
                    <div class="mt-2">
                      <p class="text-sm text-gray-500"><slot></slot></p>
                    </div>

                    <div v-if="enabledConfirmation">
                      <input type="text" v-model="confirmText" class="mt-2 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-untitled-gray-500 focus:border-untitled-gray-500 sm:text-sm" placeholder="" />
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    :disabled="loading || isDisabled"
                    class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 sm:ml-3 sm:w-auto"
                    @click="handleConfirm"
                    v-if="showConfirm"
                  >
                    {{ loading ? "Loading..." : confirmButtonLabel }}
                  </button>
                  <button
                    type="button"
                    :disabled="loading"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 sm:mt-0 sm:w-auto"
                    @click="cancelButton"
                    v-if="showCancel"
                  >
                    {{ loading ? "Loading..." : "Cancel" }}
                  </button>
                </div>
              </div>
              <!-- custom ui -->
              <div v-else>
                <div class="sm:flex sm:items-start">
                  <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <ExclamationIcon
                      class="h-6 w-6 text-gray-600"
                      aria-hidden="true"
                    />
                  </div>

                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle
                      as="h3"
                      class="text-lg font-medium leading-6 text-gray-900"
                      >{{ dialogTitle }}</DialogTitle
                    >
                    <div class="mt-2">
                      <slot name="custom_html"></slot>
                    </div>
                  </div>
                </div>
                <slot name="action_button"></slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed, defineProps,ref } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { ExclamationIcon } from "@heroicons/vue/solid";

const props = defineProps({
  okButton: {
    type: Function,
  },
  cancelButton: {
    type: Function,
  },
  open: {
    default: false,
    type: Boolean,
  },
  loading: {
    default: false,
    type: Boolean,
  },
  dialogTitle: {
    default: "Confirm",
    type: String,
  },
  confirmButtonLabel: {
    default: "Proceed",
    type: String,
  },
  isCustom: {
    type: Boolean,
    default: false,
  },
  showCancel: {
    type: Boolean,
    default: true,
  },
  showConfirm: {
    type: Boolean,
    default: true,
  },
  enabledConfirmation: {
    type: Boolean,
    default: false,
  },
});


const confirmText = ref('')

const isDisabled = computed(() => {
  if(! props.enabledConfirmation){
    return false
  }

  return confirmText.value !== "Confirm"
})

const handleConfirm = () => {
  if (props.okButton) {
    props.okButton();
  }

  confirmText.value = ''
}
</script>
