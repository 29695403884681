import { defineStore } from "pinia";
import { ref } from "vue";

export const useHomeTabsStore = defineStore("homeTabsStore", () => {
  const selectedRightSectionTab = ref("report_summary");
  const selectedMainTab = ref("featured");
  const listType = ref("grid");

  return {
    selectedRightSectionTab,
    selectedMainTab,
    listType,
  };
});
