<template>
  <div class="flex flex-col items-end w-full">
    <el-select-v2
      class="w-full"
      v-model="value"
      :options="customOptions"
      :placeholder="placeholder"
      :clearable="clearable"
      :filterable="filterable"
      :multiple="multiple"
      :allow-create="isAllowCreate && !allowCreateHybrid"
      collapse-tags
      size="large"
      :disabled="disabled"
      :loading="loading"
      v-bind="attrs"
      ref="eCombobox"
      :name="name"
      :reserve-keyword="reserveKeyword"
      placement="bottom"
      @clear="clear"
      @remove-tag="handleRemoveValue"
      :id="id"
      @change="handleValue"
      :teleported="teleported"
    >
      <template #default="{ item }" v-if="isAllowCreate || $slots.custom">
        <template v-if="!$slots.custom">
          <div
            class="flex flex-row px-1 gapy-x-4 justify-start items-center"
            v-if="typeof item.value === 'string'"
          >
            <p class="text-xs font-medium text-untitled-gray-500 mr-2">
              {{ allowCreateLabel }}:
            </p>
            <p class="font-semibold">{{ item.label }}</p>
          </div>
          <div v-else>
            {{ item.label }}
          </div>
        </template>
        <template v-else>
          <slot
            name="custom"
            :item="item"
            :allow-create="isAllowCreate"
            :allowCreateLabel="allowCreateLabel"
          />
        </template>
      </template>
      <template #footer v-if="isAllowCreate && $slots.footer">
        <slot name="footer" />
      </template>
    </el-select-v2>
    <template v-if="multiple && customOptions?.length">
      <button
        v-if="
          props?.modelValue === undefined ||
          (props?.modelValue?.length < customOptions?.length &&
            customOptions?.length &&
            allowSelectAll)
        "
        :disabled="disabled"
        @click="selectAll"
        class="e-combobox-button"
      >
        <small>Select All</small>
      </button>
      <button
        v-else
        :disabled="disabled"
        @click="deselectAll"
        class="e-combobox-button"
      >
        <small>Deselect</small>
      </button>
    </template>
  </div>

  <span class="text-red-500 text-xs italic mt-1">{{ errorMessage }}</span>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  useAttrs,
  watch,
  ref,
  onMounted,
} from "vue";
import { ElSelectV2 } from "element-plus";
import { useField } from "vee-validate";
import { isEqual as _isEqual, debounce as _debounce } from "lodash-es";
import eventBus from "@/plugins/eventBus";

const props = defineProps({
  modelValue: {
    default: null,
  },
  options: {
    type: Object,
  },
  placeholder: {
    default: "Placeholder",
    type: String,
  },
  valueKey: {
    type: String,
    default: null,
    required: true,
  },
  optionName: {
    type: String,
    default: null,
    required: true,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  name: {
    default: "",
    type: String,
  },
  rules: {
    default: [],
  },
  multiple: {
    default: false,
    type: Boolean,
  },
  filterable: {
    default: false,
    type: Boolean,
  },
  clearable: {
    default: false,
    type: Boolean,
  },
  loading: {
    default: false,
    type: Boolean,
  },
  allowCreate: {
    type: Boolean,
    default: false,
  },
  reserveKeyword: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
  },
  allowSelectAll: {
    type: Boolean,
    default: true,
  },
  allowCreateLabel: {
    type: String,
    default: "Click as new",
  },
  teleported: {
    type: Boolean,
    default: true,
  },
  allowCreateHybrid: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "clear", "removeTag", "change"]);

const attrs = useAttrs();
const allowCreateLabel = computed(() => props.allowCreateLabel);
const eCombobox = ref();
const clear = () => {
  emit("clear");
};

const selectAll = () => {
  const items = customOptions?.value?.map((item) => item.value);

  emit("update:modelValue", [...items]);
  value.value = [...items]
};

const deselectAll = () => {
  value.value = []
  emit("update:modelValue", []);
};

const validateField = (value) => {
  let error = true;

  for (let i = 0; i < props.rules?.length; i++) {
    const rule = props.rules[i];

    error = rule(value);

    if (error != true) {
      break;
    }
  }

  emit("update:modelValue", value);

  return error;
};

const customOptions = computed(() => {
  if (!props?.options?.length) {
    return [];
  }

  if (typeof props?.options[0] !== "object") {
    return props?.options?.map((option) => {
      return {
        label: option,
        value: option,
      };
    });
  }

  return props?.options?.map((option) => {
    return {
      label: option[props?.optionName],
      value: option[props?.valueKey],
    };
  });
});

const debounceRefresh = _debounce(() => {
  if(! props.modelValue) {
    return []
  }

  const selectedItems = customOptions.value
    .filter((item) => {
      return props.modelValue.some((a) => a === item.value);
    })
    .map((item) => item.value);

  emit("update:modelValue", [...selectedItems]);
  value.value = [...selectedItems]
}, 200);

const isAllowCreate = computed(() => props.allowCreate);
const allowCreateHybrid = computed(() => props.allowCreateHybrid);
watch(
  () => props.options,
  (current, prev) => {
    if (!_isEqual(current, prev)) {
      debounceRefresh();
    }
  }
);

const { errorMessage, value } = useField(props.name, validateField, {
  validateOnValueUpdate: true,
  initialValue: props?.modelValue,
});

const handleRemoveValue = (removableValue) => {
  if (props.multiple) {
    emit("removeTag", removableValue);
  }
};

const handleValue = (value) => {
  emit("change", value);
};

onMounted(() => {
  eventBus.$on("Blur_Select", () => {
    if (!eCombobox.value) return;
    eCombobox.value.dropdownMenuVisible = false;
  });
});
</script>

<style scoped>
.e-combobox-button {
  position: absolute;
  transform: translate(-36px, 7px);
  color: #9c9fa4;
  background-color: white;
  z-index: 10;
}
</style>
