import { defineStore } from "pinia";

export const usePusherStore = defineStore("pusher", {
  state: () => ({
    pusher: null,
  }),
  actions: {
    setPusher(pusherConfig) {
      this.pusher = pusherConfig;
    },
  },
});
